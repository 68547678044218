import {ThemeProvider as MuiThemeProvider, createTheme} from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#757575',
		},
		secondary: {
			main: '#EE2B39',
		},
	},
	transitions: {},
	mixins: {},
	typography: {
		fontFamily: "'Roboto'",
		fontSize: 13.5,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
	},
	button: {
		textTransform: 'none',
	},
	props: {
		MuiButton: {
			size: 'small',
		},
		MuiFilledInput: {
			margin: 'dense',
		},
		MuiFormControl: {
			margin: 'dense',
		},
		MuiFormHelperText: {
			margin: 'dense',
		},
		MuiIconButton: {
			size: 'small',
		},
		MuiInputBase: {
			margin: 'dense',
		},
		MuiInputLabel: {
			margin: 'dense',
		},
		MuiListItem: {
			dense: true,
		},
		MuiOutlinedInput: {
			margin: 'dense',
		},
		MuiFab: {
			size: 'small',
		},
		MuiTable: {
			size: 'small',
		},
		MuiTextField: {
			margin: 'dense',
		},
		MuiToolbar: {
			variant: 'dense',
		},
		MuiInputBase: {
			variant: 'dense',
		},
	},
	overrides: {
		MuiIconButton: {
			sizeSmall: {
				// Adjust spacing to reach minimal touch target hitbox
				marginLeft: 4,
				marginRight: 4,
				padding: 12,
			},
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				contained: {
					backgroundColor: '#ee2a3a',
					color: 'white',
					'&:hover': {
						backgroundColor: '#c62828',
					},
				},
				outlined: {
					backgroundColor: 'transparent',
					color: '#ee2a3a',
					border: '1px solid #ee2a3a',
					'&:hover': {
						border: '1px solid #ee2a3a',
						backgroundColor: '#FFEBEE',
					},
				},
				text: {
					backgroundColor: 'transparent',
					color: '#ee2a3a',
					'&:hover': {
						backgroundColor: '#FFEBEE',
					},
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				iconContainer: {
					'.Mui-completed.MuiStepIcon-root': {
						color: 'green',
					},
					'.Mui-active.MuiStepIcon-root': {
						color: '#ee2a3a',
					},
				},
				labelContainer: {
					'.Mui-completed': {
						color: 'green',
					},
					'.Mui-active': {
						color: '#ee2a3a',
					},
				},
			},
		},
	},
});

export const ThemeProvider = ({children}) => {
	return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
