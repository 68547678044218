import { SHIFT_CLOSED, USER_PROFILE_FETCHED } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
    switch (action.type) {
        case USER_PROFILE_FETCHED:
            if (action.profile) return { ...action.profile };
            return state;
        case SHIFT_CLOSED:
            state.isCargoShiftClosed = "1";
            state.cargoShiftCloseMsg = " Your ID Is Blocked For Today. Remitance Is Generated On This ID You Could Not Do Any Transaction ";
            return state;
        default: return state;
    }
}