import { FETCH_POSTS, USER_LOGOUT, SET_LOCALE, CARGO_POST } from '../actions/types';
import storage from 'redux-persist/es/storage';
import Cookies from 'universal-cookie';


const initialState = {

    userProfile: '',
    locale: 'en',
    statePreviousResponse:''

}
const cookies = new Cookies();
export default function (state = initialState, action) {
    //console.log("in postreucer");
    //console.log(action);
    //console.log(action.userProfile);
    // if(!action.userProfile){
    //    // console.log("clear1");
    //     //console.log(localStorage);
    //     localStorage.clear();
    //       //  console.log(cookies.get("token"));
    //         cookies.remove("token", { path: '/' });
    //         if (cookies.get("token") != undefined) {
    //             cookies.remove("token");
    //         }
    //         //console.log("after deleting token " + cookies.get("token"))
    //         //console.log("logging out")
    //         storage.removeItem('persist:root');
    //         return {
    //             ...state,
    //             locale:'en'
    //         }
    // }
    switch (action.type) {
        case FETCH_POSTS:
            cookies.set("token",action.userProfile.data.token);
            return {
                ...state,
                userProfile: action.userProfile.userName,
            }
        case CARGO_POST:
            return {
                ...state,
                statePreviousResponse: action.statePreviousResponse,
            }
        case USER_LOGOUT:
            localStorage.clear();
            console.log(cookies.get("token"));
            cookies.remove("token", { path: '/' });
            if (cookies.get("token") != undefined) {
                cookies.remove("token");
            }
            console.log("after deleting token " + cookies.get("token"))
            console.log("logging out")
            storage.removeItem('persist:root');
            return {
                ...state,
                locale:'en'
            }
        default:
            return state;
        case SET_LOCALE:
            return {
                ...state,
                locale: action.lang
            }
    }
}


