export const APP_NAME = 'Logistics';
export const GENERAL_DATE_FORMAT = "DD-MM-YYYY";
export const USER_CARGO_AGENT = 1031;
export const USER_COMMISSION = 1035;
export const USER_CLERK = 1036;
export const RTC_AGENT = 1030;
export const USER_CARGO_ADMIN = 1033;
export const USER_ADMINSTRATOR = 1000;
export const USER_CHIEF_ADMIN = 1001;
export const USER_ATM_ADMIN = 1042;
export const USER_CCC_SUPPORT = 1046;
export const USER_DYCM = 1039;
export const INSTANT_TOPUP_PAYMENT_TYPE = "89";

export const OTP_TYPE_LOGIN = '1';
export const OTP_TYPE_DELIVERY = '2';
export const OTP_TYPE_COMPLAINT = '3';

export const BOOKING_TYPE_COURIER = 0;
export const BOOKING_TYPE_PARCEL = 1;
export const BOOKING_TYPE_BULK = 3;

export const CAPTURE_IMAGE_OF_SENDER = 1;
export const CAPTURE_IMAGE_OF_ARTICLE = 2;
export const CAPTURE_IMAGE_OF_INVOICE = 3;
export const CAPTURE_IMAGE_OF_RECEIVED_RACK = 4;
export const CAPTURE_IMAGE_OF_RECEIVER = 5;
export const CAPTURE_IMAGE_OF_RECEIVER_IDPROOF = 6;
export const COURIER_GST = 18;
export const PARCEL_GST = 18;
export const BULK_GST = 12;
export const DOOR_DELIVERY_GST = 18;

export const BOOKING_WEIGHT_ROUNDOFF = 250;
export const BOOKING_PIECES = 400;
export const BOOKING_BOXES = 401;
export const CASH_PAYMENT = 405;
export const UPI_PAYMENT = 406;
export const PAYMENT_MODE_PAID = 157;
export const PAYMENT_MODE_TO_PAY = 158;
export const PAYMENT_MODE_POD = 159;
export const PAYMENT_PLAN_POSTPAID = "1";
export const PAYMENT_PLAN_PREPAID = "2";
export const CARRIER_TYPE_ROOF_TOP = 151;
export const CARRIER_TYPE_INSIDE_DICKEY = 153;
export const PRODUCT_TYPE_STEEL_PLASTIC = 1002;
export const PRODUCT_TYPE_VEGETABLE = 1003;
export const IDENTITY_MANDATORY_FROM_SHIPMENT_VALUE = 5000;
export const VEHICLE_TYPE_DGT = 610;
export const VEHICLE_TYPE_DICKEY = 609;
export const VEHICLE_TYPE_ROOFTOP = 608;
export const DOOR_DELIVERY = 142;
export const COUNTER_DELIVERY = 143;

export const COMPLAINT_STATUS_OPEN = 914;
export const COMPLAINT_STATUS_INPROGRESS = 915;
export const COMPLAINT_STATUS_SETTLED = 917;
export const COMPLAINT_STATUS_SETTLED_CLOSED = 918;
export const COMPLAINT_STATUS_CLOSED = 916;

export const CARGO_STATUS_BOOKED = 137;
export const CARGO_STATUS_MANIFESTED = 136;
export const CARGO_STATUS_RECEIVED = 134;
export const CARGO_STATUS_PARTIAL_RECEIVED = 141;
export const CARGO_STATUS_PARTIAL_DELIVERED = 147;
export const CARGO_STATUS_RECEIVED_AT_TRANSSHIPMENT = 140;
export const CARGO_STATUS_MISTOUTE_RECEIVED = 145;
export const CARGO_STATUS_READY_TO_DELIVER = 144;
export const CARGO_STATUS_DELIVERED = 135;
export const CARGO_STATUS_DEMURRAGE_APPLIED = 146;

export const ITEM_MANIFESTED = '1';
export const ITEM_NOT_MANIFESTED = '0';

export const IDENTITY_AADHAR_CODE = 'AC';
export const IDENTITY_DRIVING_LICENCE_CODE = 'DL';
export const IDENTITY_GOVT_ID_CODE = 'GOVT_ISSU_ID';
export const IDENTITY_PAN_CARD_CODE = 'PAN_CARD';
export const IDENTITY_PASSPORT_CODE = 'PASSPORT';
export const IDENTITY_VOTER_ID_CODE = 'VOTER_ID';

export const REGEX_AADHAR_CARD = /^\d{12}$/;
export const REGEX_DRIVING_LICENCE = /^[A-Z]{5}[0-9]{11}$/;
export const REGEX_PAN_CARD = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
export const REGEX_PASSPORT = /^[A-Z0-9]{8}$/;
export const REGEX_VOTER_ID = /^[A-Z]{3}[0-9]{7}$/;
export const REGEX_MOBILE_NO = /^[6-9]{1}[0-9]{9}$/;
export const REGEX_EMAIL = /^\w+([\.-]?\w+)*@([\w-]+\.)+[\w-]{2,4}$/

export const RUPEE_SYMBOl = '₹';

export const INVALID_MOBILE_NO = [
    '6666666666',
    '7777777777',
    '8888888888',
    '9999999999',
];

export const ROUTE_NOT_FOUND_MESSAGE = "No Direct Route, ADD TRANSSHIPMENT";

export const CONSTRAINT_USERNAME_MAX_LENGTH = 50;
export const CONSTRAINT_EMAIL_MAX_LENGTH = 50;
export const CONSTRAINT_ADDRESS_MAX_LENGTH = 255;
export const CONSTRAINT_LANDMARK_MAX_LENGTH = 30;
export const CONSTRAINT_CITY_MAX_LENGTH = 50;
export const CONSTRAINT_BOOKING_DESC_MAX_LENGTH = 100;
