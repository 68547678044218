import React, { useState, useMemo } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import imgBanner1 from "../../assets/images/banner-1.png";
import { Grid, Box, IconButton } from "@mui/material";
import IconBack from "@mui/icons-material/ArrowBackIosNewRounded";
import IconForward from "@mui/icons-material/ArrowForwardIosRounded";
import "./HomeCarousel.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const HomeCarousel = () => {
    const [activeStep, setActiveStep] = useState(0);
    const imagesList = useMemo(() => {
        return [imgBanner1, imgBanner1, imgBanner1];
    }, []);

    const handleNext = () => {
        if (activeStep >= imagesList.length - 1) {
            setActiveStep(0);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            setActiveStep(imagesList.length - 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <Box className="swipeable-container">
            <AutoPlaySwipeableViews
                axis="x"
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {imagesList.map((image, index) => (
                    <Grid container justifyContent="center" alignItems="center">
                        <Box
                            key={`banner-img-${index}`}
                            component="img"
                            sx={{
                                height: "60vh",
                                display: "block",
                                overflow: "hidden",
                                width: "auto",
                            }}
                            src={image}
                            alt=""
                        />
                    </Grid>
                ))}
            </AutoPlaySwipeableViews>

            <IconButton
                className="carousel-nav-button left"
                color="default"
                onClick={handleBack}
            >
                <IconBack />
            </IconButton>

            <IconButton
                className="carousel-nav-button right"
                color="default"
                onClick={handleNext}
            >
                <IconForward />
            </IconButton>

            <Box className="carousel-stepper">
                {imagesList &&
                    imagesList.map((image, index) => (
                        <Box
                            className={`carousel-stepper-item ${
                                activeStep === index ? "active" : ""
                            }`}
                            onClick={() => handleStepChange(index)}
                        />
                    ))}
            </Box>
        </Box>
    );
};

export default HomeCarousel;
