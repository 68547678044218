import {Box, Container, Grid, Typography} from '@mui/material';
import imgAbhibus from '../../assets/images/abhibus.png';
import {Link} from 'react-router-dom';
import './Footer.css';

const Footer = () => {
	return (
		<Box sx={{backgroundColor: '#484848', padding: '2rem 0.5rem'}}>
			<Container>
				<Grid container gap={3} alignItems="center">
					<Grid item xs={12} md>
						<Box component="span">
							<Link className="footer-link" to="/login">
								DOOR-DOOR SERVICES
							</Link>{' '}
							| {/* <Link className='footer-link' to="/services">SERVICES</Link>{' '}|{' '} */}
							{/* <Link className='footer-link' to="/ratecalculator">RATE CALCULATOR</Link>{' '}|{' '}  */}
							<Link className="footer-link" to="/track">
								TRACK AWB NO
							</Link>{' '}
							|{' '}
							<Link className="footer-link" to="/contact">
								CONTACT
							</Link>
						</Box>
						<Box sx={{marginTop: '1rem'}}>
							<Typography color="white">
								Copyright © 2011 apsrtconline.in, All rights reserved.
							</Typography>
						</Box>
					</Grid>
					<Grid item>
						<img src={imgAbhibus} alt="" />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default Footer;
