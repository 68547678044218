import React, { Fragment, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Paper, Typography, TextField } from '@mui/material';
import { ServiceInterceptor } from '../apis/Service';
import CargoTrackDetailTable from './CargoTrackDetailsTable';
import { parseQueryParams } from '../helpers/utilities';

const CargoTrack = () => {
    const [awbNo, updateAWBNo] = useState({
        value: '',
        errorMessage: null,
    });
    const [trackRes, updateTrackRes] = useState(null);
    const [isLoading, updateIsLoading] = useState(false);
    const [isMobile, updateIsMobile] = useState(false);

    const fetchBookingDetails = (awbNumber) => {
        if (!awbNumber && !awbNo.value) {
            const updatedAWBNo = { ...awbNo };
            updatedAWBNo.errorMessage = 'Field is required!';
            updateAWBNo(updatedAWBNo);
        } else {
            updateIsLoading(true);
            const params = { id: awbNo?.value||awbNumber  };
            ServiceInterceptor("/api/customer", "/customer/booking/getBookingDetails", "POST", params,
                res => {
                    updateIsLoading(false);
                    if (!res.error) {
                        updateTrackRes(res);
                    } else {
                        updateTrackRes({ noDataFound: true });
                    }
                },
                err => {
                    updateIsLoading(false);
                    updateTrackRes({ noDataFound: true });
                }
            );
        }
    }

    useEffect(() => {
        const queryParams = parseQueryParams(window.location.search);
        if (queryParams.awbNo) {
            updateAWBNo({ value: queryParams.awbNo });
            fetchBookingDetails(queryParams.awbNo);
        }
        if ((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)) {
            updateIsMobile(true);
        }
    }, []);

    return (
        <Fragment>
            <Grid container justifyContent='center' style={{ padding: '1rem' }}>
                <Grid item xs={12} md={12} lg={9} xl={6}>
                    <Paper style={{ padding: '1rem' }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant='h6' color='textPrimary'>Track AWB No</Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <TextField
                                    fullWidth
                                    required
                                    size="small"
                                    value={awbNo.value}
                                    variant="outlined"
                                    label="AWB No"
                                    onChange={(e) => updateAWBNo({ value: e.target.value, errorMessage: null })}
                                    error={awbNo.errorMessage}
                                    helperText={awbNo.errorMessage}
                                    inputProps={{ maxLength: '15' }}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={3}>
                                <Button variant="contained" color="primary" size='medium' onClick={fetchBookingDetails} disabled={isLoading}>Search</Button>
                            </Grid>
                        </Grid>
                    </Paper>

                    {(isLoading || trackRes) && (
                        <Fragment>
                            {isLoading && (
                                <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
                                    <Grid container justifyContent='center'>
                                        <CircularProgress size={40} thickness={3.6} color="inherit" disableShrink={true} />
                                    </Grid>
                                </Paper>
                            )}
                            {trackRes && trackRes.noDataFound && (
                                <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
                                    <Typography color='textPrimary' variant='body1'>No Data Found!</Typography>
                                </Paper>
                            )}

                            {trackRes && trackRes.serviceStatusList && (
                                <Fragment>
                                    <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} md={6} style={{ padding: '5px' }}><Typography variant='h6' style={{ fontWeight: 400 }}>Shipment Tracking Details</Typography></Grid>
                                            <Grid item xs={12} md={6} style={{ textAlign: 'end', padding: '5px' }}><Typography variant='h6' style={{ fontWeight: 400 }}>Current Status: <Typography variant='h6' component="span" color='primary'>{trackRes.currentStatus}</Typography></Typography></Grid>

                                            <Grid item xs={12} style={{ margin: '0.75rem 0' }} />

                                            <Grid item xs={12} md={6} lg={3} className={isMobile ? 'track-item' : 'mb-3'}>
                                                <label style={{ padding: "5px", fontSize: '0.9rem' }}>Tracking No</label>
                                                <div style={{ padding: "5px", fontSize: "1rem" }}>{trackRes.awbNo || trackRes.id}</div>
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={3} className={isMobile ? 'track-item' : 'mb-3'}>
                                                <label style={{ padding: "5px", fontSize: '0.9rem' }}>Origin</label>
                                                <div style={{ padding: "5px", fontSize: "1rem" }}>{trackRes.fromPlaceName}</div>
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={3} className={isMobile ? 'track-item' : 'mb-3'}>
                                                <label style={{ padding: "5px", fontSize: '0.9rem' }}>Destination</label>
                                                <div style={{ padding: "5px", fontSize: "1rem" }}>{trackRes.toPlaceName}</div>
                                            </Grid>

                                            <Grid item xs={12} md={6} lg={3} className={isMobile ? 'track-item' : 'mb-3'}>
                                                <label style={{ padding: "5px", fontSize: '0.9rem' }}>Sender Name</label>
                                                <div style={{ padding: "5px", fontSize: "1rem" }}>{trackRes.fromName}</div>
                                            </Grid>

                                            <Grid item xs={12} className={isMobile ? 'track-item' : 'mb-3'}>
                                                <label style={{ padding: "5px", fontSize: '0.9rem' }}>Sender Address</label>
                                                <div style={{ padding: "5px", fontSize: "1rem" }}>{trackRes.fromAddress} - {trackRes.senderPinCode}</div>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h6" color="primary" style={{ padding: '0.5rem 0.75rem' }}>Tracking History</Typography>
                                            </Grid>

                                            <Grid item xs={12} style={{ marginBottom: "1rem", overflowX: 'auto' }}>
                                                <CargoTrackDetailTable trackResponse={trackRes} isGuestUser />
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                    <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant='body1' style={{ fontSize: "1.2rem", padding: '0 0.75rem' }}>If you have any queries on the shipment you can raise complaint by calling APSRTC Central complaint call @&nbsp;0866-2570006</Typography>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
}
export default CargoTrack;
