import React, {Component, Fragment} from 'react';
import {withStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {useStyles} from '../styles/commercials';
import {ServiceInterceptor} from '../apis/Service';
import Typography from '@mui/material/Typography';
import {getWeightSuffix} from '../helpers/utilities';

class FareDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: true,
			date: new Date(),
			privilegeString: 'ADMV',
			fareResponse: '',
			showfare: false,
			isMobile: false,
		};
	}
	handleDrawerOpen = () => {
		this.setState({open: true});
	};

	handleChange = (event, attribute) => {
		var value = event.target.value;
		console.log('handleChange called');
		console.log(value);
		this.setState({agreementId: value});
		let errors = this.state.errors;
		switch ('agreementId') {
			case 'agreementId':
				if (true) {
					errors['agreementId'] = value == '' ? 'agreementId' + ' is required!' : '';
				}
				break;
		}
		this.setState({errors, ['agreementId']: value});
	};

	// createData = (event, attribute) => {
	//     console.log("createData called");
	//     let id = 0;
	//     id += 1;
	//     return { id, name, calories, fat, carbs, protein };
	// };

	// searchInvoice = () => {
	//     event.preventDefault();
	// };
	componentDidMount() {
		this.setState({addBooking: false});
		let requestObject = {};
		if (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1) {
			this.setState({isMobile: true});
		}
		if (this.props.requestObject && Object.keys(this.props.requestObject).length > 0) {
			requestObject = {...this.props.requestObject};
		} else {
			requestObject.totalDistance = this.props.distanceResponse.totalDistance;
			requestObject.cargoTypeId = this.props.productTypeId;
			requestObject.journeyDate = this.props.formData.txtEffectiveDate;
			requestObject.weight = this.props.weight; //cal weight
			requestObject.length = this.props.formData.length;
			requestObject.breadth = this.props.formData.breadth; //basic fare
			requestObject.height = this.props.formData.height;
			requestObject.startPlaceID = this.props.formData.startPlaceId;
			requestObject.endPlaceID = this.props.formData.endPlaceId;
			requestObject.shipmentValue = this.props.formData.shipmentValue; // driver incentive
			requestObject.bookingType = this.props.cargoTypeId;
			requestObject.senderAreaCode = this.props.formData.senderAreaCode;
			requestObject.availId = this.props.formData.availId;
			requestObject.optPacking = this.props.formData.optPacking;
			requestObject.optPlasticPacking = this.props.formData.optPlasticPacking;
			requestObject.pickUpDate = this.props.formData.pickUpDate;

			if (this.props.cargoTypeId === 0) {
				requestObject.weightInfo = [
					{
						carrierTypeId: this.props.formData.carrierTypeId,
						cargoTypeId: this.props.formData.cargoTypeId,
						pieceTypeId: this.props.formData.pieceTypeId,
						actualWeight: this.props.formData.actualweight,
						totalWeight: this.props.formData.totalweight,
						noOfpiece: this.props.formData.unit,
						productTypeId: this.props.formData.cargoTypeId,
					},
				];
			} else {
				requestObject.weightInfo = this.props.formData.weightInfo;
			}

			if (this.props.formData.dropTypeId == '142') {
				requestObject.cargoDoorDeliveryDto = {
					pinCode: this.props.formData.deliveryPincode.value,
					areaCode: this.props.formData.deliveryArea.value,
					areaName: this.props.formData.deliveryAreaName.value,
				};
			}

			requestObject.dropTypeId = this.props.formData.dropTypeId;
			requestObject.paymentMode = this.props.formData.paymentMode;
			requestObject.paymentType = this.props.formData.paymentType;
			requestObject.referenceRouteIdList = this.props.distanceResponse.referenceRouteIdList;
		}

		if (this.props.extraParams && Object.keys(this.props.extraParams).length > 0) {
			requestObject = {...requestObject, ...this.props.extraParams};
		}

		ServiceInterceptor(
			this.props.url,
			this.props.type + '/getFaresDetails',
			'POST',
			requestObject,
			(res) => {
				console.log('res fare in booking');
				console.log(res);
				if (res && !res.error) {
					console.log('setting state');
					this.setState({fareResponse: res});
					this.setState({showfare: true});
					this.props.handleFareCallback(res);
				} else {
					this.props.handleFareCallback(null);
					alert(res.message);
					this.setState({showfare: false});
					return false;
				}
			},
			(err) => {
				if (err) {
					if (err.response && err.response.status === 400) {
						alert('distance is zero please provide valid to place name');
						return false;
					}
					if (err.res && err.res.status === 408) {
						document.getElementById('root').innerHTML = err.res.data;
					}
					if (err.response && err.response.status === 408) {
						document.getElementById('root').innerHTML = err.response.data;
					}
					console.log(err);
				}
			}
		);

		const containerElem = document.querySelector('#fare-details-container');
		if (containerElem)
			containerElem.scrollIntoView({
				block: 'center',
				behavior: 'smooth',
			});
	}
	render() {
		const {classes} = this.props;
		let paperStyle = {};
		if (!this.props.hideCard) paperStyle = {padding: 10, marginTop: 10};

		return (
			<React.Fragment>
				<Paper style={paperStyle} elevation={this.props.hideCard ? 0 : 2} id="fare-details-container">
					<Grid container>
						<Grid item lg={12}>
							<Grid container spacing={0}>
								<Grid item lg={12}>
									{/* <Paper className={classes.paper}> */}
									<Typography variant="h6" style={{margin: '10px 0'}}>
										Fare Details
									</Typography>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<Grid
												container
												spacing={1}
												style={{
													textAlign: 'right',
													paddingRight: '40px',
												}}
												id="fare-details">
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Calculate Weight{' '}
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																{this.state.fareResponse.weight || 0}{' '}
																{getWeightSuffix(this.state.fareResponse.bookingType)}
															</b>{' '}
															{this.state.fareResponse.volumetricFlag == 1 && (
																<div>(Volumetric)</div>
															)}
														</Typography>
													</Grid>
												</Grid>
												{this.props.cargoTypeId != 0 &&
												this.state.fareResponse.actualTotalWeight ? (
													<Grid
														item
														xs={12}
														sm={3}
														style={{
															display: 'flex',
														}}>
														<Grid xs={12}>
															<Typography
																style={{
																	paddingTop: '0px',
																	paddingLeft: '15px',
																}}
																display="block"
																gutterBottom>
																Actual Weight{' '}
																<b
																	style={{
																		paddingLeft: '10px',
																	}}>
																	{this.state.fareResponse.totalActualWeight || 0}
																	&nbsp;
																	{getWeightSuffix(
																		this.state.fareResponse.bookingType
																	)}
																</b>
															</Typography>
														</Grid>
													</Grid>
												) : null}
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Total Distance{' '}
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																{this.state.fareResponse.totalDistance
																	? this.state.fareResponse.totalDistance
																	: 0}{' '}
																Km
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Total No. of Pieces{' '}
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																{this.state.fareResponse.noOfPieces
																	? this.state.fareResponse.noOfPieces
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>

												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Driver Incentive
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.driverIncentives
																	? this.state.fareResponse.driverIncentives
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Hamali Loading
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.loadingCharge
																	? this.state.fareResponse.loadingCharge
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Hamali Unloading
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.unloadingCharge
																	? this.state.fareResponse.unloadingCharge
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Transshipment
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.transhipmentCharges
																	? this.state.fareResponse.transhipmentCharges
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Package Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.packageCharges
																	? this.state.fareResponse.packageCharges
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Plastic Package Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.plasticPackageCharges
																	? this.state.fareResponse.plasticPackageCharges
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															To Pay Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.toPayChargesToBeCollect
																	? this.state.fareResponse.toPayChargesToBeCollect
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Insurance
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.insurance
																	? this.state.fareResponse.insurance
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>

												<Fragment>
													<Grid
														item
														xs={12}
														sm={3}
														style={{
															display: 'flex',
														}}>
														<Grid xs={12}>
															<Typography
																style={{
																	paddingTop: '0px',
																	paddingLeft: '15px',
																}}
																display="block"
																gutterBottom>
																CGST
																<b
																	style={{
																		paddingLeft: '10px',
																	}}>
																	₹
																	{this.state.fareResponse.cgst
																		? this.state.fareResponse.cgst
																		: 0}
																</b>
															</Typography>
														</Grid>
													</Grid>
													<Grid
														item
														xs={12}
														sm={3}
														style={{
															display: 'flex',
														}}>
														<Grid xs={12}>
															<Typography
																style={{
																	paddingTop: '0px',
																	paddingLeft: '15px',
																}}
																display="block"
																gutterBottom>
																SGST
																<b
																	style={{
																		paddingLeft: '10px',
																	}}>
																	₹
																	{this.state.fareResponse.sgst
																		? this.state.fareResponse.sgst
																		: 0}
																</b>
															</Typography>
														</Grid>
													</Grid>
												</Fragment>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															IGST
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.igst
																	? this.state.fareResponse.igst
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Net Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.basicFare
																	? this.state.fareResponse.basicFare
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Door Pickup Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹{this.state.fareResponse.customerPickupCharges || 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{paddingTop: '0px', paddingLeft: '15px'}}
															display="block"
															gutterBottom>
															Bank Transaction
															<b style={{paddingLeft: '10px'}}>
																₹ {this.state.fareResponse.bankTranxFee || 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															POD Pickup Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹{this.state.fareResponse.locationPickupCharges || 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Agent Pickup Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.pickupCharges
																	? this.state.fareResponse.pickupCharges
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>

												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															POD Delivery Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹{0}
															</b>
														</Typography>
													</Grid>
												</Grid>

												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Door Delivery Charges
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹{this.state.fareResponse.doorDeliveryCharges || 0}
															</b>
														</Typography>
													</Grid>
												</Grid>

												{/* {this.props.formData && this.props.formData.isNomanPlace == "1" && (
                          <Grid item xs={12} sm={3} style={{ display: "flex" }}>
                            <Grid xs={12}>
                              <Typography style={{ paddingTop: "0px", paddingLeft: "15px" }} display="block" gutterBottom>Unmanned Location Charges<b style={{ paddingLeft: "10px" }}>₹ {this.state.fareResponse.unMannedLoactionCharges || 0}</b></Typography>
                            </Grid>
                          </Grid>
                        )} */}
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															ToPay RoundOff Amount
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.toPayRoundOff
																	? this.state.fareResponse.toPayRoundOff
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															RoundOff Amount
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.roundOff
																	? this.state.fareResponse.roundOff
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
															}}
															display="block"
															gutterBottom>
															Sub Total
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.subTotalAmount
																	? this.state.fareResponse.subTotalAmount
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>
												<Grid item xs={12} sm={3} style={{display: 'flex'}}>
													<Grid xs={12}>
														<Typography
															style={{
																paddingTop: '0px',
																paddingLeft: '15px',
																color: this.props.showPayableAmount ? '' : 'blue',
																fontWeight: this.props.showPayableAmount
																	? '400'
																	: '700',
															}}
															display="block"
															gutterBottom>
															Total Bill Amount
															<b
																style={{
																	paddingLeft: '10px',
																}}>
																₹
																{this.state.fareResponse.totalAmount
																	? this.state.fareResponse.totalAmount
																	: 0}
															</b>
														</Typography>
													</Grid>
												</Grid>

												{this.props.showPayableAmount && (
													<React.Fragment>
														<Grid
															item
															xs={12}
															sm={3}
															style={{
																display: 'flex',
															}}>
															<Grid xs={12}>
																<Typography
																	style={{
																		paddingTop: '0px',
																		paddingLeft: '15px',
																	}}
																	display="block"
																	gutterBottom>
																	Amount Paid
																	<b
																		style={{
																			paddingLeft: '10px',
																		}}>
																		₹{this.state.fareResponse.paidAmount || 0}
																	</b>
																</Typography>
															</Grid>
														</Grid>
														<Grid
															item
															xs={12}
															sm={3}
															style={{
																display: 'flex',
															}}>
															<Grid xs={12}>
																<Typography
																	style={{
																		paddingTop: '0px',
																		paddingLeft: '15px',
																		color: 'green',
																	}}
																	display="block"
																	gutterBottom>
																	<b>Payable Amount</b>
																	<b
																		style={{
																			paddingLeft: '10px',
																		}}>
																		₹{this.state.fareResponse.paybleAmount || 0}
																	</b>
																</Typography>
															</Grid>
														</Grid>
													</React.Fragment>
												)}
											</Grid>

											<Grid container spacing={1}>
												<Grid item xs={12} sm={4} style={{display: 'flex'}}>
													{/* <Grid xs={4}>
                                                <Typography style={{ paddingTop: "0px", paddingLeft: "15px" }} display="block" gutterBottom>Payment Type <span style={{ color: "red" }}>{this.state.fareResponse.weight ? this.state.fareResponse.weight : 0 }</span></Typography>
                                            </Grid> */}
													<Grid xs={8}>
														{/* <Select style={{ minWidth: '100%' }}
                                                    placeholder="Payment Type"
                                                    size="small"
                                                    defaultValue={10}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>Payment Type</em>
                                                    </MenuItem>
                                                    <MenuItem value={10}>Paid</MenuItem>
                                                    <MenuItem value={20}>Twenty</MenuItem>
                                                    <MenuItem value={30}>Thirty</MenuItem>
                                                </Select> */}
													</Grid>
												</Grid>
											</Grid>

											<Grid container spacing={3}>
												<Grid
													item
													xs={12}
													style={{
														display: 'flex',
														textAlign: 'right',
													}}>
													<Grid xs={12}>
														{/* <Button type="submit" style={{ marginTop: "10px", paddingLeft: "15px", marginRight: "20px" }} variant="contained" color="success" onClick={handleSubmit}>Add Shipment</Button>
                                                <Button style={{ marginTop: "10px", paddingLeft: "15px", marginRight: "30px" }} variant="contained" color="error">Reset</Button> */}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									{/* </Paper> */}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</React.Fragment>
		);
	}
}
// export default FareDetails;
export default withStyles(useStyles, {withTheme: true})(FareDetails);
