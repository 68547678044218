import React, { Component } from 'react'
import {deepPurple} from '@mui/material/colors';
import { withStyles, createStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';



const drawerWidth = 220;
export const useStyles = theme => (
{
  root: {
    display: 'flex',
  },
  toolbar: {
    minHeight: 30,
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      backgroundColor: theme.palette.primary.main,
      height: 50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',

    },
    appBar: {
      height: 50,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    MainHead: {
      color: '#ffffff',
      fontWeight: 800,
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(6.47),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
    },
    listItemIcon: {
      minWidth: 34
    },
    container: {
      // paddingTop: theme.spacing(2),
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    purpleAvatar: {
      color: '#fff',
      backgroundColor: deepPurple[500],
    },
    mainHeading: {
      paddingRight: theme.spacing(4)
    },
    mainHeadingText: {
      fontWeight: 1000
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    listItemText: {
      fontSize: '0.9em',//Insert your required size
    },
    drawerInnerAppBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawerInner: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerInnerPaper: {
      width: drawerWidth,
    },
    drawerInnerToolbar: theme.mixins.toolbar,
    drawerInnerContent: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    navsecondaryHeading  : {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    navheading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      fontWeight: 700,
    },
    navroot: {
      width: '100%',
    },
    drawerToolbar :theme.mixins.toolbar,
    invoicepaper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'inherit',
      flexDirection: 'column',
    },
    innertab :{
      background: "rgba(71, 122, 178)"
    },
    reportContent: {
      flexGrow: 1,
      height: '100vh',
      paddingTop: '30px'
    },
    
  });


export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
  elevation={0}
  getContentAnchorEl={null}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  {...props}
  />
  ));

export const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

