import {useState} from 'react';
import { Grid, Typography, TextField, Button, Box, Container } from "@mui/material";
import {useNavigate} from 'react-router-dom';

const TrackAwb = () => {
    const navigate = useNavigate();
    const [awbNo, setAwbNo] = useState('');

    const handleOnTrackClick = () => {
        navigate(`/track?awbNo=${awbNo}`);
    }

    return (
        <Box sx={{ padding: "1rem", position: "relative" }}>
            <Box
                className="track-bg"
                sx={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: "50%",
                    width: "100%",
                    backgroundColor: "#c6c6d5",
                }}
            />
            <Container>
                <Grid
                    container
                    alignItems="center"
                    gap={2}
                    sx={{
                        backgroundColor: "#595959",
                        borderRadius: "1rem",
                        padding: "1rem",
                        position: "relative",
                    }}
                >
                    <Grid item xs={12} md={2}>
                        <Typography
                            sx={{ textAlign: { xs: "center", md: "end" } }}
                            color="white"
                        >
                            Enter AWB No
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={awbNo}
                            name="awbNo"
                            onChange={(e) => setAwbNo(e.target.value)}
                            sx={{
                                marginBottom: "0 !important",
                                backgroundColor: "white",
                                borderRadius: "4px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Button variant="contained" onClick={handleOnTrackClick} fullWidth>
                            Track
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default TrackAwb;
