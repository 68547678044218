import React, {useState} from 'react';
import OTPInput from 'react-otp-input';
import {Box, Button, Grid, InputAdornment, TextField, Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import {ServiceInterceptor} from '../apis/Service.jsx';
import Snackbars from '../common/Snackbar.jsx';
import bgHome from '../assets/images/bg-login.jpg';
import {useNavigate} from 'react-router-dom';
import './Authorization.css';

function numberOnly(value) {
	let reg = /^[0-9]*$/;
	return reg.test(value);
}

export const setCookie = ({name, data, expires, domain, path = '/', priority = 'HIGH', secure = true}) => {
	document.cookie = `${name}=${data}; expires=${expires}; priority=${priority}; ${
		domain ? `domain=${domain};` : ''
	} path=${path}; ${secure ? 'Secure:true;' : null}`;
};

function setAuthToken(authToken) {
	const expDate = new Date(Date.now() + 60 * 60 * 1000); // Current time + 1 hour in milliseconds

	setCookie({
		name: 'client_auth_token',
		data: authToken,
		expires: expDate.toUTCString(),
	});
}
const Authorization = ({classes, history}) => {
	const navigate = useNavigate();
	const [mobileNum, setMobileNum] = useState('');
	const [snackbar, updateSnackBar] = useState(null);
	const [mobileNumError, setMobileNumError] = useState('');
	const [showOtpScreen, setShowOtpScreen] = useState(false);
	const [otp, setOtp] = useState('');
	const [otpError, set0tpError] = useState('');
	const [otpType, setOtpType] = useState('');
	const [otpSuccesMessage, setOtpSuccesMessage] = useState('');
	const [customerRes, setCustomerRes] = useState(null);
	const mobileNumHandler = (e) => {
		const value = e.currentTarget.value;
		if (numberOnly(value) && value.length <= 10) {
			setMobileNum(value);
			setMobileNumError('');
		}
	};

	const showSnackBar = (type = 'success', message) => {
		if (!message) return;

		updateSnackBar({type, message});
		setTimeout(() => {
			hideSnackBar();
		}, 3000);
	};
	const hideSnackBar = () => {
		updateSnackBar(null);
	};
	const otpHandler = (value) => {
		setOtp(value);
		set0tpError(false);
	};
	const sendOtp = (event) => {
		if (mobileNum.length < 10) {
			setMobileNumError('Please Enter Mobile Number');
			return;
		}
		const payLoad = {
			mobileNumber: mobileNum,
		};

		ServiceInterceptor(
			'/api/customer/',
			'/cust/fetchOtp',
			'POST',
			payLoad,
			(res) => {
				if (res.status === '0') {
					setOtpType(res.otpType);
					setShowOtpScreen(true);
					setOtpSuccesMessage(res.message);
					showSnackBar('success', res.message);
				} else {
					showSnackBar('error', res.message);
				}
			},
			(err) => {
				setMobileNumError(err.message || 'There is some issue');
			},
			{'Content-Type': 'multipart/form-data'}
		);
	};

	const verifyOtp = () => {
		if (otp.length < 6) {
			const message = otp.length > 0 ? 'Please Enter Valid OTP' : 'Please Enter OTP';
			set0tpError(message);
			return;
		}
		const payLoad = {
			otpNumber: otp,
			mobileNumber: mobileNum,
			otpType,
		};
		ServiceInterceptor(
			'/api/customer/',
			'/cust/validateOtp',
			'POST',
			payLoad,
			(res) => {
				if (res.status === '1') {
					set0tpError(res.message);
					setOtp('');
					showSnackBar('error', res.message);
				} else if (res.status === '0' && res.error === false) {
					const authToken = res.jwtToken;
					console.log(authToken, 'authToken');
					setAuthToken(authToken);
					setCustomerRes(res);
					const customerStringObj = JSON.stringify(res);
					localStorage.setItem('customerDetails', customerStringObj);
					showSnackBar('success', res.message);
					navigate('/customers/booking/form', {replace: true});
				}
			},
			(err) => {
				showSnackBar('error', err.message);
				set0tpError(err.message || 'There is some error!');
			},
			{'Content-Type': 'multipart/form-data'}
		);
	};

	const reSendOtp = () => {
		set0tpError('');
		const payLoad = {
			mobileNumber: mobileNum,
			otpType,
		};

		ServiceInterceptor(
			'/api/customer/',
			'/cust/resendOtp',
			'POST',
			payLoad,
			(res) => {
				if (res.status === '0') {
					setOtpType(res.otpType);
					setOtpSuccesMessage(res.message);
				} else {
					// console.log(error)
				}
			},
			(err) => {},
			{'Content-Type': 'multipart/form-data'}
		);
	};

	return (
		<Grid
			style={{
				backgroundImage: 'url(' + bgHome + ')',
				backgroundPosition: 'center bottom',
				width: '100%',
				height: 'calc(100vh - 66px)',
				backgroundSize: '100%',
				backgroundRepeat: 'no-repeat',
				backgroundColor: '#D6FCFF',
			}}>
			{snackbar && snackbar.message && (
				<Snackbars msg={snackbar.message} type={snackbar.type || 'info'} functype="custom" position="right" />
			)}
			{!customerRes && (
				<Grid container component="main" direction={'column'} alignItems={'center'}>
					<Box
						style={{
							marginTop: '3.75rem',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							paddingTop: '1rem',
						}}>
						<Typography component="h5" variant="h5">
							Login for Customer booking
						</Typography>
						<Box>
							{!showOtpScreen && (
								<Grid>
									<TextField
										InputProps={{
											startAdornment: <InputAdornment position="start">+91</InputAdornment>,
										}}
										margin="normal"
										variant="outlined"
										required
										fullWidth
										id="mobileNum"
										label="Mobile Number"
										helperText={mobileNumError}
										error={mobileNumError}
										autoFocus
										value={mobileNum}
										onChange={mobileNumHandler}
									/>
									<Button
										color="primary"
										fullWidth
										variant="contained"
										style={{marginTop: 16, marginBottom: 16}}
										onClick={sendOtp}>
										Get OTP
									</Button>
								</Grid>
							)}
							{showOtpScreen && (
								<Grid>
									{otpSuccesMessage && (
										<Typography style={{color: 'green', marginTop: 8}}>
											{otpSuccesMessage}
											<Typography component="span" color="primary">
												{mobileNum}
											</Typography>
										</Typography>
									)}
									<OTPInput
										shouldAutoFocus
										renderInput={(props) => <input {...props} />}
										renderSeparator={<span>&nbsp;</span>}
										value={otp}
										onChange={otpHandler}
										numInputs={6}
										inputType="tel"
										inputStyle={{
											padding: '10px',
											width: '3.5rem',
											height: '3rem',
											fontSize: '1rem',
											borderRadius: '6px',
											border: '1px solid lightGrey',
											backgroundColor: '#f4f4f4',
										}}
										containerStyle={{
											marginTop: '10px',
										}}
									/>
									{otpError && (
										<Typography style={{marginTop: 5}} color="error" component="p">
											{otpError}
										</Typography>
									)}
									<Grid container style={{marginTop: 16, marginBottom: 16}} spacing={1}>
										<Grid item sm={6} xs={12}>
											<Button color="primary" fullWidth variant="contained" onClick={verifyOtp}>
												Verify
											</Button>
										</Grid>
										<Grid item sm={6} xs={12}>
											<Button color="primary" variant="contained" fullWidth onClick={reSendOtp}>
												ReSend OTP
											</Button>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Box>
					</Box>
				</Grid>
			)}
			{/* {customerRes && (<CustomerBooking customerDetails={customerRes} />)} */}
		</Grid>
	);
};

export default withStyles(null, {withTheme: true})(Authorization);

// export default Authorization;
// export default withStyles((useStyles), { withTheme: true })(CustomerLogin);
