import React from 'react';
import {createBrowserRouter} from 'react-router-dom';
import Authorization from './authorization/Authorization';
import Layout from './booking/Layout';
import NotFound from './NotFound/NotFound';
import CustomerBookingForm from './booking/CustomerBookingForm';
import CustomerBookings from './booking/CustomerBookings';
import CustomerPaymentConfirmation from './booking/CustomerPaymentConfirmation';
import Logout from './booking/Logout';
import CustomerInvoice from './booking/CustomerInvoice';
import Home from './Home/Home';
import RootLayout from './common/RootLayout';
import CustomerTermsAndConditions from './booking/CustomerTermsAndConditions';
import PageUnderDevelopment from './common/PageUnderDevelopment';
import CargoTrack from './track/CargoTrack';
import WizardCustomer from './booking/WizardCustomer';
import Contact from './Home/Contact/Contact';
import CustomerCancellation from './booking/CustomerCancellation';

const routes = createBrowserRouter([
	{
		path: '/',
		element: <RootLayout />,
		children: [
			{
				path: 'login',
				element: <Authorization />,
			},
			// {
			// 	path: 'services',
			// 	element: <PageUnderDevelopment />,
			// },
			// {
			// 	path: 'ratecalculator',
			// 	element: <PageUnderDevelopment />,
			// },
			{
				path: 'track',
				element: <CargoTrack />,
			},
			{
				path: 'contact',
				element: <Contact />,
			},
			{
				path: '/',
				element: <Home />,
				exact: true,
			},
		],
	},
	{
		path: 'customers',
		element: <Layout />,
		children: [
			{
				path: 'booking/form',
				element: <WizardCustomer />,
			},
			{
				path: 'bookings/list',
				element: <CustomerBookings />,
			},
			{
				path: 'booking/confirmation',
				element: <CustomerPaymentConfirmation />,
			},
			{
				path: 'booking/termsAndConditions',
				element: <CustomerTermsAndConditions />,
			},
			{
				path: `booking/cancellation/:bookingId`,
				element: <CustomerCancellation />,
			},
		],
	},

	{
		path: '/customer/invoice',
		element: <CustomerInvoice />,
	},
	{
		path: 'logout',
		element: <Logout />,
	},
	{
		path: '*',
		element: <NotFound />,
	},
]);
export default routes;
