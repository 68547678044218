import React from "react";
import { BOOKING_TYPE_BULK, BOOKING_TYPE_COURIER, BOOKING_TYPE_PARCEL, BOOKING_WEIGHT_ROUNDOFF, IDENTITY_AADHAR_CODE, IDENTITY_DRIVING_LICENCE_CODE, IDENTITY_PAN_CARD_CODE, IDENTITY_PASSPORT_CODE, IDENTITY_VOTER_ID_CODE, REGEX_AADHAR_CARD, REGEX_DRIVING_LICENCE, REGEX_PAN_CARD, REGEX_PASSPORT, REGEX_VOTER_ID, VEHICLE_TYPE_DGT, VEHICLE_TYPE_DICKEY, VEHICLE_TYPE_ROOFTOP } from "./constants";

export const validateForm = (formState, updateFormState) => {
    let isFormValid = true;
    const updatedFormState = { ...formState };
    Object.keys(updatedFormState).map(key => {
        const formItem = updatedFormState[key];
        if (formItem.ignore === true) return key;
        if (formItem.required && !formItem.value) {
            formItem.errorMessage = formItem.label ? `${formItem.label} is required` : 'Required!';
        } else if (formItem.validate && formItem.validate.regex && formItem.validate.regex.test(formItem.value) === false) {
            formItem.errorMessage = formItem.validate.message || 'Invalid data';
        } else if (formItem.validate && formItem.validate.validator && formItem.validate.validator(formState) === false) {
            formItem.errorMessage = formItem.validate.message || 'Invalid data';
        } else {
            formItem.errorMessage = null;
        }
        isFormValid = isFormValid && !formItem.errorMessage;
        updatedFormState[key] = formItem;
    });
    updateFormState(updatedFormState);
    return isFormValid;
}

export const parseQueryParams = (query) => {
    if (query.charAt(0) === "?") query = query.substring(1);
    const vars = query.split('&');
    const queryParams = {};
    for (var i = 0; i < vars.length; i++) {
        const equlasToIndex = vars[i].indexOf("=");
        queryParams[vars[i].substring(0, equlasToIndex)] = vars[i].substring(equlasToIndex + 1);
    }
    return queryParams;
}

export const roundOffBookingWeight = (weight) => {
    if (!weight) return weight;
    let gramsOffset = (parseInt(((weight.split(".")[1] || "0") + "00").substring(0, 3)) % BOOKING_WEIGHT_ROUNDOFF);
    if (gramsOffset > 0) {
        gramsOffset = (BOOKING_WEIGHT_ROUNDOFF - gramsOffset) / 1000;
        weight = parseFloat(weight) + gramsOffset;
    }
    return parseFloat(weight).toFixed(2);
}

export const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

export const getImageFromBase64String = (base64String, style = {}) => {
    return <img src={`data:image/png;base64,${base64String}`} style={style} />
}

export const scrollToErrorElement = (behavior = "smooth") => {
    const errorElement = document.querySelector(".MuiFormHelperText-root.Mui-error");
    if (errorElement)
        errorElement.scrollIntoView({ block: 'center', behavior });
}

export const pastDatesOnly = (current) => {
    if (!current) return false;
    return new Date(current._d).getTime() < new Date().getTime();
}

export const exportToExcel = (tableRef) => {
    if (tableRef && tableRef.current && (tableRef.current.outerHTML || (tableRef.current.tableRef && tableRef.current.tableRef.outerHTML))) {
        const tableData = (tableRef.current.tableRef && tableRef.current.tableRef.outerHTML) || tableRef.current.outerHTML;
        window.location.href = 'data:application/vnd.ms-excel,' + encodeURIComponent(tableData);
    }
}

export const getWeightSuffix = (bookingType) => {
    let weightSuffix = '';
    if (bookingType == BOOKING_TYPE_COURIER)
        weightSuffix = 'GM';
    else if (bookingType == BOOKING_TYPE_PARCEL)
        weightSuffix = "KG";
    else if (bookingType == BOOKING_TYPE_BULK)
        weightSuffix = "KG";
    return weightSuffix;
}

export const validateIdentity = (identityTypeId, identityNumber) => {
    if (!identityTypeId || !identityNumber) return true;

    let isValid = true;
    if (!identityNumber.errorMessage && identityNumber.value) {
        let regex = '';
        switch (identityTypeId.selectedItem.code) {
            case IDENTITY_AADHAR_CODE:
                regex = new RegExp(REGEX_AADHAR_CARD);
                break;
            // case IDENTITY_DRIVING_LICENCE_CODE:
            //     regex = new RegExp(REGEX_DRIVING_LICENCE);
            //     break;
            case IDENTITY_PAN_CARD_CODE:
                regex = new RegExp(REGEX_PAN_CARD);
                break;
            case IDENTITY_PASSPORT_CODE:
                regex = new RegExp(REGEX_PASSPORT);
                break;
            // case IDENTITY_VOTER_ID_CODE:
            //     regex = new RegExp(REGEX_VOTER_ID);
            //     break;
            default:
                regex = new RegExp();
        }
        if (regex.test(identityNumber.value) === false || new Set(identityNumber.value.split("")).size === 1) {
            isValid = false;
            identityNumber.errorMessage = "Please enter valid id";
        }
    }
    return isValid;
}

export const getVehicleTypeNameFromId = (id) => {
    if (VEHICLE_TYPE_DGT == id) return "DGT";
    else if (VEHICLE_TYPE_DICKEY == id) return "DICKEY";
    else if (VEHICLE_TYPE_ROOFTOP == id) return "ROOF TOP";
    else return "";
}

export const pageScrollTop = () => {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 250);
}

export const scrollIntoView = (viewString) => {
    const elem = document.querySelector(viewString);
    if (elem)
      elem.scrollIntoView({ block: 'center', behavior: 'smooth' });
}
