import {CUSTOMER_DATA} from '../actions/types';

const initialState = {};
export default function (state = initialState, action) {
	switch (action.type) {
		case CUSTOMER_DATA:
			return {
				...state,
				data: { ...state.data, ...action.data},
			};
		default:
			return state;
	}
}
