import React, {useEffect, useState} from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import {ServiceInterceptor} from '../apis/Service';
import CheckIcon from '@mui/icons-material/Check';
import {Typography} from '@mui/material';
import ScaleIcon from '@mui/icons-material/Scale';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useNavigate} from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Snackbars from '../common/Snackbar.jsx';

import './CustomerBookings.css';

const CustomerBookings = () => {
	const navigate = useNavigate();
	const [customerState, setCustomerState] = useState([]);
	const [snackbar, updateSnackBar] = useState(null);
	const [bookingConfig, setBookingConfig] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [slotList, setSlotList] = useState([]);
	const [pickUpDate, setPickUpDate] = useState({value: new Date(), errorMessage: null});
	const [selectedSlot, setSelectedSlot] = useState(null);
	let customerObj = JSON.parse(localStorage.getItem('customerDetails'));
	const [selectedItem, setSelectedItem] = useState(null);
	const [avaiId, setAvailId] = useState('');
	const [open, setOpen] = useState(false);
	const [isSlotLoading, setIsSlotLoading] = useState(false);
	const [hasRefundModal, setHasRefundModal] = useState(false);
	const [hasFareModal, setHasFareModal] = useState(false);
	const [originalFare, setOriginalFare] = useState(null);
	const [refundFare, setRefundFare] = useState(null);
	const handleOpen = (selectedItem) => {
		setPickUpDate((prev) => ({...prev, value: ''}));
		setSelectedItem(selectedItem);
		setOpen(true);
		fetchSlot(new Date(selectedItem.scheduledDate.split(' ')[0]), selectedItem);
	};
	const handleCancellation = (item) => {
		navigate(`/customers/booking/cancellation/${item.bookingId}`);
	};
	const handleClose = (name) => {
		setOpen(false);
	};
	const showSnackBar = (type = 'success', message) => {
		if (!message) return;

		updateSnackBar({type, message});
		setTimeout(() => {
			hideSnackBar();
		}, 3000);
	};
	const hideSnackBar = () => {
		updateSnackBar(null);
	};
	const getUserByMobileNumber = () => {
		const payLoad = {
			fromMobileNumber: customerObj.customerAddress.mobileNumber,
		};
		setIsLoading(true);
		ServiceInterceptor(
			'/api/customer/',
			'customer/getDetailsByMobile',
			'POST',
			payLoad,
			(res) => {
				setIsLoading(false);
				if (res.status === '0') {
					setCustomerState(res.bookingDetails);
				} else {
					// showSnackBar('error', res.message)
				}
			},
			(err) => {
				setIsLoading(false);
				// setMobileNumError(err.message || "There is some issue");
			},
			{'Content-Type': 'multipart/form-data'}
		);
	};
	const onRefundHandlerClick = (item) => {
		setRefundFare(item);
		setHasRefundModal(true);
	};
	const FareDetailsTable = (originalFare, name) => {
		return (
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Description</TableCell>
							<TableCell align="right">Amount</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{originalFare.bankTransactionFee >= 0 && (
							<TableRow>
								<TableCell>Bank Transaction Fee:</TableCell>
								<TableCell align="right">₹{originalFare.bankTransactionFee}</TableCell>
							</TableRow>
						)}
						{originalFare.basicAmount >= 0 && (
							<TableRow>
								<TableCell>Net Charges:</TableCell>
								<TableCell align="right">₹{originalFare.basicAmount}</TableCell>
							</TableRow>
						)}
						{originalFare.basicFare >= 0 && (
							<TableRow>
								<TableCell>Net Charges:</TableCell>
								<TableCell align="right">₹{originalFare.basicFare}</TableCell>
							</TableRow>
						)}
						{originalFare.cgst >= 0 && (
							<TableRow>
								<TableCell>CGST:</TableCell>
								<TableCell align="right">₹{originalFare.cgst || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.igst >= 0 && (
							<TableRow>
								<TableCell>IGST:</TableCell>
								<TableCell align="right">₹{originalFare.igst || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.doorDeliveryCharges >= 0 && (
							<TableRow>
								<TableCell>Door Delivery Charges:</TableCell>
								<TableCell align="right">₹{originalFare.doorDeliveryCharges || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.deliveryCharges >= 0 && (
							<TableRow>
								<TableCell>Door Delivery Charges:</TableCell>
								<TableCell align="right">₹{originalFare.deliveryCharges || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.pickupCharges >= 0 && (
							<TableRow>
								<TableCell>Door Pickup Charges:</TableCell>
								<TableCell align="right">₹{originalFare.pickupCharges || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.doorPickupCharges >= 0 && (
							<TableRow>
								<TableCell>Door Pickup Charges:</TableCell>
								<TableCell align="right">₹{originalFare.doorPickupCharges || 0}</TableCell>
							</TableRow>
						)}
						{(originalFare.driverIncenitve || originalFare.driverIncentives) >= 0 && (
							<TableRow>
								<TableCell>Driver Incentive:</TableCell>
								<TableCell align="right">
									₹{originalFare.driverIncenitve || originalFare.driverIncentives || 0}
								</TableCell>
							</TableRow>
						)}
						{originalFare.insurance >= 0 && (
							<TableRow>
								<TableCell>Insurance:</TableCell>
								<TableCell align="right">₹{originalFare.insurance || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.loadingCharges >= 0 && (
							<TableRow>
								<TableCell>Hamali Loading:</TableCell>
								<TableCell align="right">₹{originalFare.loadingCharges || 0}</TableCell>
							</TableRow>
						)}
						{(originalFare.unloadingCharges || originalFare.unLoadingCharges) >= 0 && (
							<TableRow>
								<TableCell>Hamali Unloading:</TableCell>
								<TableCell align="right">
									₹{originalFare.unloadingCharges || originalFare.unLoadingCharges || 0}
								</TableCell>
							</TableRow>
						)}
						{originalFare.pgCharges >= 0 && (
							<TableRow>
								<TableCell>PG Charges:</TableCell>
								<TableCell align="right">₹{originalFare.pgCharges || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.packingCharges >= 0 && (
							<TableRow>
								<TableCell>Package Charges:</TableCell>
								<TableCell align="right">₹{originalFare.packingCharges || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.plasticPackingCharges >= 0 && (
							<TableRow>
								<TableCell>Plastic Package Charges:</TableCell>
								<TableCell align="right">₹{originalFare.plasticPackingCharges || 0}</TableCell>
							</TableRow>
						)}
						{(originalFare.roundOffAmount || originalFare.roundOffAmt) >= 0 && (
							<TableRow>
								<TableCell>Round Off Amount:</TableCell>
								<TableCell align="right">
									₹{originalFare.roundOffAmount || originalFare.roundOffAmt || 0}
								</TableCell>
							</TableRow>
						)}
						{originalFare.sgst >= 0 && (
							<TableRow>
								<TableCell>SGST:</TableCell>
								<TableCell align="right">₹{originalFare.sgst || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.transhipmentCharges >= 0 && (
							<TableRow>
								<TableCell>Transshipment:</TableCell>
								<TableCell align="right">₹{originalFare.transhipmentCharges || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.subTotalAmount >= 0 && (
							<TableRow>
								<TableCell>Sub Total Amount:</TableCell>
								<TableCell align="right">₹{originalFare.subTotalAmount || 0}</TableCell>
							</TableRow>
						)}
						{originalFare.totalAmount >= 0 && (
							<TableRow>
								<TableCell>Total {name === 'original' ? 'Bill' : 'Refund'} Amount:</TableCell>
								<TableCell align="right">₹{originalFare.totalAmount || 0}</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	const onOriginalHandlerClick = (item) => {
		setOriginalFare(item?.originalFares);
		setHasFareModal(true);
	};
	const RefundCloseHandler = (name) => {
		setHasRefundModal(false);
	};
	const onFareCloseHandler = () => {
		setHasFareModal(false);
	};
	const handleSave = () => {
		const requestObject = {
			bookingId: selectedSlot.bookingId || '',
			scheduledDate: pickUpDate.value || '',
			availId: avaiId || '',
		};
		ServiceInterceptor(
			'/api/customer',
			'/updateSlot',
			'POST',
			requestObject,
			(res) => {
				if (res.status === '0') {
					alert(res.message);
					getUserByMobileNumber();
				} else {
					showSnackBar('error', res.message);
				}
			},
			(err) => {
				console.error(err);
			}
		);
		setOpen(false);
	};
	const [selectedButton, setSelectedButton] = useState(null);
	useEffect(() => {
		if (customerObj && customerObj.customerAddress && customerObj.customerAddress.mobileNumber) {
			fetchBookingConfiguration();
			getUserByMobileNumber();
		}
		if (typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1)
			setIsMobile(true);
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (pickUpDate.value) {
			setSelectedButton(null);
			setAvailId('');
		}
	}, [pickUpDate.value]);
	const fetchBookingConfiguration = () => {
		const params = {
			bookingFrom: '1',
		};
		ServiceInterceptor(
			'/api/customer',
			'/customer/getConfiguration',
			'POST',
			params,
			(res) => {
				if (res && res.error === false) {
					setBookingConfig(res);
				}
			},
			(err) => {}
		);
	};
	const disableDate = (date) => {
		const muiDate = new Date(date);
		const shouldDisableDates = bookingConfig?.advSlotBookingDays;
		let maxDate = new Date();
		maxDate.setDate(maxDate.getDate() + shouldDisableDates);
		return muiDate.getTime() >= maxDate.getTime();
	};
	const disableMonth = (date) => {
		const muiMonth = new Date(date);
		const today = new Date();
		const currentMonth = today.getMonth();
		const currentYear = today.getFullYear();
		const month = muiMonth.getMonth();
		const year = muiMonth.getFullYear();
		return year > currentYear || (year === currentYear && month > currentMonth + 1);
	};
	const fetchSlot = (date, item) => {
		const formatedDate = new Date(date).toISOString().split('T')[0].split('-').reverse().join('/');
		setSelectedSlot(item);
		const updatedFormValues = {...pickUpDate};
		updatedFormValues.value = formatedDate;
		updatedFormValues.errorMessage = null;
		setPickUpDate(updatedFormValues);
		fetchSlotById(item.placeId, formatedDate);
	};
	const fetchSlotById = (placeId, date) => {
		const requestObject = {
			placeId: placeId,
			scheduledDate: date,
		};
		setSlotList([]);
		setIsSlotLoading(true);
		ServiceInterceptor(
			'/api/customer',
			'/getSlotById',
			'POST',
			requestObject,
			(res) => {
				setIsSlotLoading(false);
				if (res.status === '0' && res.slotScheduleDetails.slotBookingDto.length > 0) {
					setSlotList(res.slotScheduleDetails.slotBookingDto);
				}
			},
			(err) => {
				setIsSlotLoading(false);
				console.error(err);
			}
		);
	};
	const handleButtonClick = (buttonIndex, avaiId) => {
		setSelectedButton(buttonIndex);
		setAvailId(avaiId);
	};
	return (
		<Box style={{margin: '1rem'}} className="booking-details-container">
			{snackbar && snackbar.message && (
				<Snackbars msg={snackbar.message} type={snackbar.type || 'info'} functype="custom" position="right" />
			)}
			{isLoading ? (
				<Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
					<CircularProgress />
					<Typography>Loading...</Typography>
				</Grid>
			) : (
				<Grid container>
					{customerState &&
						customerState.length > 0 &&
						customerState.map((item) => {
							return (
								<Grid item xs={12} style={{padding: '1rem'}}>
									<Card>
										<CardContent>
											<Grid
												container
												className="my-bookings-item-header"
												alignItems="center"
												style={{columnGap: '1rem'}}>
												<Grid>
													{item.bookingType.toLowerCase() === 'parcel' ? (
														<LocalShippingIcon />
													) : (
														<EmailIcon />
													)}
												</Grid>
												<Grid>
													<Typography variant="caption" color={'gray'}>
														Booking ID
													</Typography>
													<Typography variant="h6">{item.bookingId}</Typography>
												</Grid>
												<Grid
													container
													className="mid-section"
													style={{width: 'auto', flexGrow: '1', columnGap: '1rem'}}>
													<Grid>
														<Typography variant="caption" color={'gray'}>
															Booking Date
														</Typography>
														<Typography variant="subtitle1">{item.bookedDate}</Typography>
													</Grid>
													<Grid>
														<Typography variant="caption" color={'gray'}>
															From
														</Typography>
														<Typography variant="subtitle1">{item.fromPlace}</Typography>
													</Grid>
													<Grid>
														<Typography variant="caption" color={'gray'}>
															To
														</Typography>
														<Typography variant="subtitle1">{item.toPlace}</Typography>
													</Grid>
												</Grid>
												<Grid item style={{flexGrow: '1', textAlign: 'right', width: '120px'}}>
													<Typography variant="h6">{item.status}</Typography>
												</Grid>
											</Grid>

											<Divider style={{marginBottom: '1rem'}} />
											<Grid container style={{marginBottom: '1rem'}} rowGap={1}>
												<Chip
													label={`OBREFNO : ${item.obRefNo}`}
													variant="filled"
													style={{marginRight: '12px'}}
												/>

												<Chip
													label={`PG : ${item.paymentType} | ₹${item.paidAmountAtPG}`}
													variant="filled"
													style={{marginRight: '12px'}}
												/>
												<Chip
													icon={<ScaleIcon style={{fontSize: '16px', paddingLeft: '8px'}} />}
													label={item.weight}
													style={{marginRight: '12px'}}
												/>

												<Chip
													icon={
														<ExpandMoreIcon
															style={{fontSize: '16px', paddingLeft: '8px'}}
														/>
													}
													label={`Total Bill Amount: ₹${item.totalAmount}`}
													variant="filled"
													style={{marginRight: '12px'}}
													onClick={() => onOriginalHandlerClick(item)}
												/>
												{item?.cancellationDto && (
													<>
														<Chip
															label={`Cancelled Date:${
																item.cancellationDto.cancelledDate.split(' ')[0]
															}`}
															variant="filled"
															style={{marginRight: '12px'}}
														/>
														<Chip
															label={`Cancelled Reason: ${item.cancellationDto.cancellationReason}`}
															variant="filled"
															style={{marginRight: '12px'}}
														/>
														<Tooltip
															title={
																<>
																	<Grid container direction={'column'}>
																		<Grid>
																			RoundOff Amount: ₹
																			{item.cancellationDto.roundOffAmt}
																		</Grid>
																		<Grid>
																			Basic Fare : ₹
																			{item.cancellationDto.basicFare}
																		</Grid>

																		<Grid>
																			PG Fare : ₹
																			{item.cancellationDto?.pgCharges || 0}
																		</Grid>
																		<Grid>
																			Pickup Charges : ₹
																			{item.cancellationDto?.pickupCharges || 0}
																		</Grid>
																	</Grid>
																</>
															}>
															<Chip
																icon={
																	<ExpandMoreIcon
																		style={{fontSize: '16px', paddingLeft: '8px'}}
																	/>
																}
																label={`Refund Amount: ₹${item.cancellationDto.totalAmount}`}
																variant="filled"
																onClick={() => onRefundHandlerClick(item)}
																style={{marginRight: '12px'}}
															/>
														</Tooltip>
													</>
												)}
												<>
													{item?.scheduledDate &&
														item?.slotStartTime &&
														item?.slotEndTime && (
															<Chip
																label={`Slot Date and Timings: ${
																	item.scheduledDate.split(' ')[0]
																} | ${item.slotStartTime} - ${item.slotEndTime}`}
																variant="filled"
																style={{marginRight: '12px'}}
															/>
														)}
													<Chip
														label={`Booked Date: ${item.bookedDate}`}
														variant="filled"
														style={{marginRight: '12px'}}
													/>
												</>
											</Grid>
											<Grid container justifyContent={'space-between'} rowGap={1}>
												<Chip
													label={`Receiver Name : ${item.receiverName}`}
													variant="outlined"
													style={{marginRight: '12px'}}
												/>
												<Grid container justifyContent={'end'}>
													{item.slotAvailToModify && (
														<>
															<Button
																color="primary"
																onClick={() => handleOpen(item)}
																variant="outlined">
																Edit Slot
															</Button>
														</>
													)}
													{item.cancellable && (
														<Button
															style={{marginLeft: '.75rem'}}
															color="primary"
															onClick={() => handleCancellation(item)}
															variant="outlined">
															Cancellation
														</Button>
													)}
												</Grid>
											</Grid>
										</CardContent>
									</Card>
								</Grid>
							);
						})}
				</Grid>
			)}
			<Dialog fullWidth={true} open={open}>
				<DialogTitle sx={{m: 0, p: 2}} id="customized-dialog-title">
					Update Slot | Booking Id : {selectedItem?.bookingId}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers style={{height: isMobile ? 'unset' : '600px'}}>
					<Grid className="bookings-calender" style={{padding: '0 0.5rem'}}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								defaultValue={dayjs(new Date(selectedItem?.scheduledDate?.split(' ')[0]))}
								slotProps={{
									textField: {
										size: 'small',
										disabled: isMobile ? false : true,
										name: 'pickUpDate',
										error: pickUpDate.errorMessage ? true : false,
										helperText: pickUpDate.errorMessage,
									},
								}}
								label="Pickup Date"
								disablePast
								// disabled={
								// 	!formValues.senderAddress.area.value
								// }
								shouldDisableDate={disableDate}
								shouldDisableMonth={disableMonth}
								closeOnSelect={true}
								onChange={(event) => fetchSlot(event, selectedItem)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid lg={12} container justifyContent={'flex-start'} alignItems={'center'}>
						<Grid lg={12} xs={12}>
							{slotList?.length > 0 && (
								<Typography variant="h6" fontSize={'16px'}>
									Pick up Slots
								</Typography>
							)}
						</Grid>

						{isSlotLoading ? (
							<Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
								<CircularProgress />
								<Typography>Loading...</Typography>
							</Grid>
						) : slotList.length > 0 ? (
							slotList?.map((item, index) => {
								return (
									<Grid
										key={index}
										style={{
											display: 'inline-block',
											margin: '10px',
										}}>
										<Tooltip
											title={
												<>
													<div>
														Slot Timings: {item.startTime} - {item.endTime}
													</div>
												</>
											}>
											<Button
												onClick={() => handleButtonClick(index, item.availId)}
												variant={selectedButton === index ? 'contained' : 'outlined'}
												color={selectedButton === index ? 'primary' : 'secondary'}>
												{selectedButton === index && (
													<IconButton
														style={{
															padding: '0',
															marginRight: '2px',
															paddingBottom: '2px',
															color: 'white',
														}}
														onClick={() => handleButtonClick(index, item.availId)}
														color={selectedButton === index ? 'primary' : 'secondary'}>
														<CheckIcon />
													</IconButton>
												)}
												{`${item.startTime} - ${item.endTime}`}
											</Button>
										</Tooltip>
									</Grid>
								);
							})
						) : (
							<Typography variant="body2">
								Selected Date is Expired , Please Select Current Date or Future Dates For Slots
							</Typography>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleSave} disabled={!avaiId}>
						Save changes
					</Button>
				</DialogActions>
			</Dialog>
			{refundFare && (
				<Dialog fullWidth open={hasRefundModal} style={{height: '500px'}}>
					<DialogTitle sx={{m: 0, p: 2}}>Refund Details</DialogTitle>
					<IconButton
						aria-label="close"
						onClick={RefundCloseHandler}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}>
						<CloseIcon />
					</IconButton>
					<DialogContent dividers style={{padding: '0'}}>
						{Object.keys(refundFare).length
							? FareDetailsTable(refundFare.cancellationDto, 'refund')
							: 'no records found!'}
					</DialogContent>
				</Dialog>
			)}
			{originalFare && (
				<Dialog fullWidth open={hasFareModal} style={{height: '500px'}}>
					<DialogTitle sx={{m: 0, p: 2}}>Orginal Fare Details</DialogTitle>
					<IconButton
						aria-label="fareclose"
						onClick={onFareCloseHandler}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}>
						<CloseIcon />
					</IconButton>
					<DialogContent dividers style={{padding: '0'}}>
						{Object.keys(originalFare).length > 0
							? FareDetailsTable(originalFare, 'original')
							: 'no records found!'}
					</DialogContent>
				</Dialog>
			)}
		</Box>
	);
};

export default CustomerBookings;
