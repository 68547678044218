import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import  Autocomplete  from '@mui/material/Autocomplete';
import { validateIdentity } from '../helpers/utilities';

const IDENTITY_MAX_LENGTH = {
    AC: 12,
    DL: 16,
    GOVT_ISSU_ID: 25,
    PAN_CARD: 10,
    PASSPORT: 8,
    VOTER_ID: 10
}

const Identity = ({ identityList, identityTypeId, identityNumber, onChange, handleInvalidId, isMandatory = false, style = {} }) => {
    const handleChange = (event) => {
        onChange && onChange(event);
    }

    function handleAutoCompleteSelect(name, value, valueObj, callBack) {
        onChange && onChange({ target: { name: 'identityNumber', value: '' }})
        callBack && callBack({ target: { name, value, selectedItem: valueObj || {} } });
    }

    function handleOnBlur(event) {
        const { value } = event.target;
        if ((isMandatory || value) && validateIdentity(identityTypeId, identityNumber) === false) {
            handleInvalidId && handleInvalidId("Invalid Id");
            const elem = document.querySelector("input[name='identityNumber']");
            if (elem && elem.focus) elem.focus();
        }
    }

    return (
        <Box style={style}>
            <Typography variant="button" component="div" style={{ color: '#6b6565', marginBottom: '0.5rem', backgroundColor: "#ebebeb", padding: '3px 10px' }}>Identity</Typography>
            <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "0 6px" }}>
                    <Autocomplete
                        name="identityTypeId"
                        options={identityList || []}
                        getOptionLabel={(option) => option.name || ""}
                        renderInput={(params) => <TextField required={isMandatory} {...params} label='Identity Type' size="small" variant="outlined" error={identityTypeId && identityTypeId.errorMessage ? true : false} helperText={identityTypeId && identityTypeId.errorMessage} />}
                        value={identityTypeId && identityTypeId.selectedItem}
                        onChange={(_, newValue) => handleAutoCompleteSelect("identityTypeId", newValue ? newValue["id"] : null, newValue, onChange)}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "0 6px" }}>
                    <TextField
                        fullWidth
                        name="identityNumber"
                        size="small"
                        value={identityNumber && identityNumber.value}
                        variant="outlined"
                        required={isMandatory}
                        label='Identity Number'
                        onChange={handleChange}
                        error={identityNumber && identityNumber.errorMessage ? true : false}
                        helperText={identityNumber && identityNumber.errorMessage}
                        inputProps={{ maxLength: IDENTITY_MAX_LENGTH[identityTypeId.selectedItem && identityTypeId.selectedItem.code] || 25 }}
                        onBlur={handleOnBlur}
                        autoComplete="off"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default Identity;