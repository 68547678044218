import React, {Fragment, useEffect} from 'react';
import {Box, Grid, TextField, Typography} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
	CONSTRAINT_ADDRESS_MAX_LENGTH,
	CONSTRAINT_CITY_MAX_LENGTH,
	CONSTRAINT_LANDMARK_MAX_LENGTH,
} from '../helpers/constants';
// import Snackbars from '../common/Snackbar';

const CustomerAddress = ({
	title = 'Address',
	isMandatory,
	houseNo,
	area,
	landmark,
	city,
	state,
	pincode,
	onChange,
	pickupDeliveryElement,
	statesList,
	deliveryPincodesList,
	style = {},
	disabled = false,
	formValues,
	sourceCounter,
	setFormValues,
	name,
	config,
	isRequired,
}) => {
	// const [snackbar, updateSnackBar] = useState(null);
	useEffect(() => {
		if (formValues.sourceLocation.selectedItem && formValues.sourceLocation.selectedItem.display_name) {
			const places = formValues.sourceLocation.selectedItem.display_name.trim().split(',').splice(0, 2);
			if (name === 'sender') {
				if (places.length > 0) {
					onChange &&
						onChange({
							target: {
								name: 'landmark',
								value: places[1].slice(0, 29),
							},
						});
				}

				if (places.length > 0 && places[1]) {
					onChange &&
						onChange({
							target: {
								name: 'city',
								value: places[0].slice(0, 49),
							},
						});
				}
			}
		}
		//eslint-disable-next-line
	}, [formValues.sourceLocation.selectedItem]);

	const handleChange = (event, validationObj) => {
		if (event.target.name === 'pincode') {
			const selectedArea =
				(event.target.selectedItem &&
					event.target.selectedItem.areaPlaceList &&
					event.target.selectedItem.areaPlaceList[0]) ||
				{};
			onChange &&
				onChange({
					target: {
						name: 'area',
						value: selectedArea.placeCode,
						selectedItem: selectedArea,
					},
				});
		}
		onChange && onChange(event, validationObj);
	};

	return (
		<Box style={style}>
			{/* {snackbar && snackbar.message && (
				<Snackbars msg={snackbar.message} type={snackbar.type || 'info'} functype="custom" position="right" />
			)} */}
			<Typography
				variant="button"
				component="div"
				style={{color: '#6b6565', marginBottom: '0.5rem', backgroundColor: '#ebebeb', padding: '3px 10px'}}>
				{title}
			</Typography>
			<Grid container>
				{pickupDeliveryElement && (
					<Grid item xs={12} md={6} style={{padding: '0 6px'}}>
						{pickupDeliveryElement}
					</Grid>
				)}

				<Grid item xs={12} md={pickupDeliveryElement ? 6 : 12} style={{padding: '0 6px'}}>
					<TextField
						fullWidth
						disabled={disabled}
						required={isMandatory || isRequired}
						name="houseNo"
						size="small"
						value={houseNo && houseNo.value}
						variant="outlined"
						label="Flat, House no."
						onChange={(e) =>
							handleChange(e, {
								maxLength: CONSTRAINT_ADDRESS_MAX_LENGTH,
							})
						}
						error={houseNo && houseNo.errorMessage ? true : false}
						helperText={houseNo && houseNo.errorMessage}
						inputProps={{maxLength: 255}}
						autoComplete="off"
					/>
				</Grid>

				<Grid item xs={12} md={4} style={{padding: '0 6px'}}>
					<TextField
						fullWidth
						required={isMandatory}
						disabled={disabled}
						name="landmark"
						size="small"
						value={landmark && landmark.value}
						variant="outlined"
						label="Landmark "
						onChange={(e) =>
							handleChange(e, {
								maxLength: CONSTRAINT_LANDMARK_MAX_LENGTH,
							})
						}
						error={landmark && landmark.errorMessage ? true : false}
						helperText={landmark && landmark.errorMessage}
						inputProps={{maxLength: 120}}
						autoComplete="off"
					/>
				</Grid>

				<Grid item xs={12} md={4} style={{padding: '0 6px'}}>
					<TextField
						fullWidth
						disabled={disabled}
						required={isMandatory}
						name="city"
						size="small"
						value={city && city.value}
						variant="outlined"
						label="Town/City"
						onChange={(e) =>
							handleChange(e, {
								inputType: 'string',
								maxLength: CONSTRAINT_CITY_MAX_LENGTH,
							})
						}
						error={city && city.errorMessage ? true : false}
						helperText={city && city.errorMessage}
						inputProps={{maxLength: 45}}
						autoComplete="off"
					/>
				</Grid>

				{deliveryPincodesList && deliveryPincodesList.length > 0 && (
					<Fragment>
						<Grid item xs={12} md={6} style={{padding: '0 6px'}}>
							<Autocomplete
								fullWidth
								size="small"
								options={deliveryPincodesList.reduce((a, c) => {
									if (pincode && pincode.value && c.pincode && c.pincode === pincode.value)
										a.push(...c.areaPlaceList);
									else if (!pincode || !pincode.value) a.push(...c.areaPlaceList);
									return a;
								}, [])}
								getOptionLabel={(option) => {
									return option ? option.placeName : '';
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="Area"
										size="small"
										variant="outlined"
										error={area.errorMessage ? true : false}
										helperText={area.errorMessage}
									/>
								)}
								value={area.selectedItem || {}}
								onChange={(_, newValue) =>
									handleChange({
										target: {
											name: 'area',
											value: (newValue && newValue.placeCode) || '',
											selectedItem: newValue,
										},
									})
								}
							/>
						</Grid>

						<Grid item xs={12} md={6} style={{padding: '0 6px'}}>
							<Autocomplete
								fullWidth
								size="small"
								options={deliveryPincodesList}
								getOptionLabel={(option) => {
									return option ? option.pincode : '';
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										label="Pincode"
										size="small"
										variant="outlined"
										error={pincode.errorMessage ? true : false}
										helperText={pincode.errorMessage}
									/>
								)}
								value={pincode.selectedItem || {}}
								onChange={(_, newValue) =>
									handleChange({
										target: {
											name: 'pincode',
											value: (newValue && newValue.pincode) || '',
											selectedItem: newValue,
										},
									})
								}
							/>
						</Grid>
					</Fragment>
				)}
			</Grid>
		</Box>
	);
};

export default CustomerAddress;
