import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, CardHeader, Chip, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import ServiceInterceptor from '../apis/Service';
const CustomerActiveCounter = ({ close, isMobile }) => {
	const [activeCountersRes, setActiveCounter] = useState([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchValue, setSearchValue] = useState('');
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const handleSearchChange = (event) => {
		setSearchValue(event.target.value);
		setPage(0);
	};
	const filteredCounters = activeCountersRes.filter((counter) => {
		return Object.values(counter).some((value) =>
			value.toString().toLowerCase().includes(searchValue.toLowerCase())
		);
	});
	useEffect(() => {
		ServiceInterceptor('/api/customer', '/customer/activeCounters', 'GET', null, (res) => {
			if (res.status === '0') {
				setActiveCounter(res.areas);
			}
		});
	}, []);
	const columns = [];
	const columnsToShow = {
		district: 'District',
		pincode: 'Pincode',
		placeName: 'Place Name',
		areaCode: 'Area Code',
		areaName: 'Area Name',
	};
	activeCountersRes &&
		activeCountersRes.length > 0 &&
		//eslint-disable-next-line
		Object.keys(columnsToShow || {}).map((header) => {
			if (activeCountersRes[0].hasOwnProperty(header))
				columns.push({
					name: header,
					label: (columnsToShow[header] || '').toUpperCase(),
					options: {
						filter: true,
						sort: true,
					},
				});
		});
	return (
		<Grid>
			<Grid container justifyContent={'space-between'} alignItems={'center'}>
				<Grid item>
					<TextField
						size='small'
						label="Search Counter..."
						variant="outlined"
						value={searchValue}
						onChange={handleSearchChange}
						style={{ margin: '10px' }}
					/>
				</Grid>
				<Grid item style={{ textAlign: 'end' }}>
					<CloseIcon style={{ cursor: 'pointer' }} onClick={() => close(false)} />
				</Grid>
			</Grid>


			{!isMobile && filteredCounters.length > 0 ? <TableContainer sx={{ maxHeight: 440 }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow >
							{columns.map((column) => (
								<TableCell
									key={column.label}
								//   align={column.align}
								//   style={{ minWidth: column.minWidth }}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody >
						{
							filteredCounters.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
								return (
									<TableRow hover tabIndex={-1} >
										{columns.map((column) => {
											const value = row[column.name];
											return (
												<TableCell
													key={Math.random() * Number(column.pincode)}
												// align={column.align}
												>

													{value}
												</TableCell>
											);
										})}
									</TableRow>
								)
							})
						}
					</TableBody>
				</Table>
			</TableContainer> :
				isMobile ?
					filteredCounters.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
						return (<Card style={{marginBottom:'8px'}} >
							<Typography variant='h6' padding={'.5rem'}>Counter Details</Typography >
							<CardContent style={{paddingTop:'0'}}>
							{columns.map((column) => (
								<Chip size='small' label={`${column.label}: ${row[column.name]}`} style={{margin:'4px',padding:'2px', paddingTop:'0'}} key={Math.random() * Number(column.pincode)}/>
							))}
							</CardContent>
						</Card>)
					})
					// <Typography>cards display</Typography>
					:
					<Card>
						<CardContent>
							<Typography variant="h6" color="textSecondary" gutterBottom>
								No records found!
							</Typography>
						</CardContent>
					</Card>
			}
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={activeCountersRes?.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Grid>
	);
};

export default CustomerActiveCounter;
