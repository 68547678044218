import './App.css';
import routes from './Routes';
import {RouterProvider} from 'react-router-dom';
import {ThemeProvider} from './styles';
import {Provider} from 'react-redux';
import store from './store';
import Cookies from 'universal-cookie';
import {useEffect} from 'react';

function App() {
	const cookie = new Cookies();
	useEffect(() => {
		console.log(window.location.pathname);
		if (!cookie.get('client_auth_token') && window.location.pathname.includes('customers/booking')) {
			window.location.replace('/');
		} else if (cookie.get('client_auth_token') && !window.location.pathname.includes('customers/booking')) {
			window.location.replace('customers/booking/form');
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Provider store={store}>
			<ThemeProvider>
				<RouterProvider router={routes} />
			</ThemeProvider>
		</Provider>
	);
}

export default App;
