import React from 'react';
import Header from './Header';
import {Outlet} from 'react-router-dom';
import {CssBaseline} from '@mui/material';

const RootLayout = () => {
    return (
        <>
            <CssBaseline />
            <Header />
            <Outlet />
        </>
    );
}
 
export default RootLayout;