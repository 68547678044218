import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import {styled} from '@mui/system';
import {amber, green} from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const MySnackbarContentWrapper = styled(SnackbarContent)(({theme, variant}) => ({
	backgroundColor:
		variant === 'success'
			? green[600]
			: variant === 'error'
			  ? theme.palette.error.dark
			  : variant === 'info'
			    ? theme.palette.primary.main
			    : amber[700],
	color: theme.palette.getContrastText(
		variant === 'warning' ? amber[700] : variant === 'info' ? theme.palette.primary.main : green[600]
	),
	display: 'flex',
	alignItems: 'center',
	'& .MuiSvgIcon-root': {
		fontSize: 20,
		opacity: 0.9,
		marginRight: theme.spacing(1),
	},
}));

function Snackbars(props) {
	let successContent = '';
	let errorContent = '';
	let position = 'center';
	let verticalposition = 'top';
	if (props.functype && props.functype === 'Delete') {
		successContent = props.msg + ' Deleted successfully';
		errorContent = 'Error While Deleting ' + props.msg;
	} else if (props.functype && props.functype === 'Update') {
		successContent = props.msg + ' Updated successfully';
		errorContent = 'Error While Updating ' + props.msg;
	} else if (props.functype && props.functype === 'Report') {
		successContent = 'Report generated successfully';
		errorContent = 'Error While generating report';
	} else if (props.functype && props.functype === 'UserForgotPassword') {
		successContent = 'Password saved successfully';
		errorContent = 'Error While saving password';
	} else if (props.functype && props.functype === 'Send Email') {
		successContent = 'password recovery email has been sent to your registered email';
		errorContent = 'Error While sending mail';
	} else if (props.functype && props.functype === 'custom') {
		successContent = props.msg;
		errorContent = props.msg;
	} else if (props.functype && props.functype === 'PreBooking') {
		successContent = props.msg + ' Confirmed successfully';
		errorContent = 'Error While Confirming Pre-Booking';
	} else {
		successContent = props.msg + ' Added successfully';
		errorContent = 'Error While Adding ' + props.msg;
	}
	if (props.position && props.position === 'right') {
		position = 'right';
	}
	if (props.verticalposition && props.verticalposition === 'top') {
		verticalposition = 'top';
	}
	const [open, setOpen] = useState(true);
	const handleClose = (event, reason) => {
		console.log('handleClose called');
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	return (
		<Snackbar
			anchorOrigin={{
				vertical: verticalposition,
				horizontal: position,
			}}
			open={open}
			autoHideDuration={8000}
			onClose={handleClose}
			style={{top: verticalposition === 'top' ? '90px' : 'inherit'}}>
			<div>
				<MySnackbarContentWrapper
					onClose={handleClose}
					variant={props.type}
					message={props.type === 'success' ? successContent : errorContent}
					action={[
						<IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
							<CloseIcon />
						</IconButton>,
					]}
				/>
			</div>
		</Snackbar>
	);
}
MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};
export default Snackbars;
