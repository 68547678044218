import React from 'react';
import {AppBar, Grid} from '@mui/material';
import leftlogo from '../assets/images/apsrtc-cargo-logo.png';
import DrawerMenu from './DrawerMenu.jsx';

const CustomerAppbar = () => {
	return (
		<AppBar style={{backgroundColor: '#ee2a3a', position: 'static'}} elevation={false}>
			<Grid container alignItems="center">
				<Grid item xs>
					<img src={leftlogo} alt="APSRTC" style={{height: '60px', width: 'auto', padding: '8px'}} />
				</Grid>

				<Grid item style={{marginRight: '0.5rem'}}>
					<DrawerMenu />
				</Grid>
			</Grid>
		</AppBar>
	);
};

export default CustomerAppbar;
