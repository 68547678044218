import {Box, Container, Grid} from '@mui/material';
import {Link} from 'react-router-dom';
import HomeCarousel from '../HomeCarousel/HomeCarousel';
import imgBanner2 from "../../assets/images/banner-2.png";
import './Hero.css';

const Hero = () => {
    return (
        <Box className="hero-bg-image">
            <Container style={{ paddingTop: '2rem' }}>
                <Grid container>
                    <Grid item xs={12} md={6} lg={7} sx={{height: '60vh', padding: "1rem 1rem 0"}}>
                        <Link to="/login">
                            <img
                                src={imgBanner2}
                                className="img-fluid"
                                width="100%"
                                height="100%"
                                alt=""
                                style={{maxWidth: '100%', objectFit: 'contain', objectPosition: 'left'}}
                            />
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={6} lg={5}>
                        <HomeCarousel />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Hero;
