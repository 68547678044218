import React, {useEffect, useRef, useState} from 'react';
import CustomerBookingForm from './CustomerBookingForm';
import {Button, Chip, Grid, Paper, Stack, Step, StepContent, StepLabel, Stepper, Typography} from '@mui/material';
import {useSelector} from 'react-redux';

const WizardCustomer = () => {
	const customer = useSelector((state) => state.customer.data);
	const ref = useRef();
	const [currentStep, setCurrentStep] = useState(1);
	const [isMobile, setIsMobile] = useState(false);

	const [completedStep, setCompletedStep] = useState(null);
	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 600);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	const steps = [
		{
			label: 'Booking Type',
			description: 'Please select the booking type for your parcel or envelop.',
			element: (
				<Chip
					label={`Booking selected : ${customer?.bookingTypeMode === '0' ? 'Envelop' : 'Parcel'}`}
					variant="outlined"
					size="small"
				/>
				// <Typography variant="body2">{}</Typography>
			),
			// element: `Booking selected : ${customer?.bookingTypeMode === '1' ? 'Envelop' : 'Parcel'}`,
		},
		{
			label: 'Sender Information',
			description:
				'The sender information field includes details about the sender (name, address, contact), and the pickup counter is where the sender drops off items for shipment or delivery.',
			// element: ,
			element: (
				<Stack direction="row" spacing={1}>
					{customer?.fromName?.value && (
						<Chip label={customer?.fromName?.value} variant="outlined" size="small" />
					)}
					{customer?.fromMobile?.value && (
						<Chip label={customer?.fromMobile?.value} variant="outlined" size="small" />
					)}
					{customer?.senderAddress?.area?.value && (
						<Chip
							label={`From : ${customer?.senderAddress?.area?.value}`}
							variant="outlined"
							size="small"
						/>
					)}
				</Stack>
			),
		},
		{
			label: 'Receiver Information',
			description:
				'The receiver information section provides details about the recipient of a package, including their name, address, and contact information. Additionally, the sender may choose between counter pickup or door delivery for the recipient to receive the item.',
			element: (
				<Stack direction={'row'}>
					{customer?.toName?.value && (
						<Chip label={customer?.toName?.value} variant="outlined" size="small" />
					)}
					{customer?.toMobile?.value && (
						<Chip
							label={customer?.toMobile?.value}
							variant="outlined"
							size="small"
							style={{marginLeft: '.5rem'}}
						/>
					)}
					{customer?.receiverAddress?.area?.value && (
						<Chip
							label={`To : ${customer?.receiverAddress?.area?.value}`}
							variant="outlined"
							size="small"
							style={{marginLeft: '.5rem'}}
						/>
					)}
				</Stack>
			),
		},
		{
			label: 'Weight Information',
			description:
				'Weight information section typically includes the total weight of the package and may be expressed in units such as kilograms. Providing accurate weight details ensures proper handling and efficient delivery of the shipment',
			element: (
				<React.Fragment>
					{customer?.totalWeightItem > 0 && (
						<Chip label={`Total Weight : ${customer?.totalWeightItem}`} variant="outlined" size="small" />
					)}
				</React.Fragment>
			),
		},
		{
			label: 'Booking Summary',
			description:
				'A booking summary section is a concise overview of the key details related to a booked shipment or service.',
			element: (
				<Stack direction="row" spacing={1}>
					{customer?.shipmentValue?.value && (
						<Chip label={customer?.shipmentValue?.value} variant="outlined" size="small" />
					)}
					{customer?.desc?.value && <Chip label={customer?.desc?.value} variant="outlined" size="small" />}
				</Stack>
			),
		},
		{label: 'Article Image', description: 'A image section where package image is added.'},
		{
			label: 'Payment Information',
			description:
				'Payment information includes details like the chosen payment method, card information (for credit/debit cards), billing address, transaction amount, and payment status. It is crucial for secure and accurate financial transactions.',
		},
	];
	const handleNext = () => {
		let isFormValid = true;
		if (currentStep === 2) {
			isFormValid = ref.current.validateSenderInfo();
		} else if (currentStep === 3) {
			isFormValid = ref.current.validateReceiverInfo();
		} else if (currentStep === 4) {
			isFormValid = ref.current.validateWeightInfo();
		} else if (currentStep === 5) {
			isFormValid = ref.current.validateBookingInfo();
		} else if (currentStep === 6) {
			isFormValid = ref.current.validateArticeImage();
		}

		if (isFormValid) {
			setCurrentStep((prevActiveStep) => prevActiveStep + 1);
			if (currentStep > completedStep) {
				setCompletedStep(currentStep);
			}
		}
	};

	const handlePrev = () => {
		setCurrentStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleFinish = () => {
		ref.current.OnConfirmLogistics();
		return;
	};
	return (
		<Grid container>
			<Grid item lg={8} padding={'4px'}>
				{isMobile && (
					<Grid maxWidth={'100vw'} bgcolor={'white'} padding={'8px'} style={{overflowX: 'scroll'}}>
						<Stepper activeStep={currentStep - 1} alternativeLabel orientation="horizontal">
							{steps.map((step, i) => (
								<Step
									onClick={() => {
										if (completedStep !== null && completedStep >= i + 1) {
											setCurrentStep(i + 1);
										} else if (i <= completedStep) {
											setCurrentStep(i + 1);
										}
									}}
									key={step.label}>
									<StepLabel>{step.label}</StepLabel>
								</Step>
							))}
						</Stepper>
					</Grid>
				)}
				<CustomerBookingForm
					currentStep={currentStep}
					setCurrentStep={setCurrentStep}
					completedStep={completedStep}
					ref={ref}
				/>
			</Grid>
			<Grid item lg={4}>
				{!isMobile && (
					<Grid item style={{padding: '1rem'}}>
						<Paper elevation={1} style={{position: 'sticky', top: '0'}}>
							<Grid container bgcolor={'rgb(238, 42, 58)'} padding={'.5rem'}>
								<Typography component="h6" variant="h5" marginRight={'12px'} color={'white'}>
									Booking Progress :
								</Typography>
								<Typography component="h6" variant="h5" color={'white'}>
									{`${currentStep}/${steps.length}`}
								</Typography>
							</Grid>
							<Stepper
								activeStep={currentStep - 1}
								alternativeLabel
								orientation="vertical"
								style={{padding: '1em'}}>
								{steps.map((step, i) => (
									<Step
										onClick={() => {
											if (completedStep !== null && completedStep >= i + 1) {
												setCurrentStep(i + 1);
											} else if (i <= completedStep) {
												setCurrentStep(i + 1);
											}
										}}
										key={step.label}>
										<StepLabel style={{textAlign: 'left'}}>{step.label}</StepLabel>
										<StepContent TransitionComponent={'None'} className="step-description">
											{/* <Chip label={step.element} variant="outlined" size="small" /> */}
											{step.element}
										</StepContent>
									</Step>
								))}
							</Stepper>
							<Grid container justifyContent={'space-between'}>
								{currentStep > 1 && (
									<Button style={{margin: '12px'}} variant="contained" onClick={handlePrev}>
										Previous
									</Button>
								)}
								{currentStep < steps.length ? (
									<Button
										variant="contained"
										style={{margin: '12px', padding: '.4rem 2rem'}}
										onClick={handleNext}>
										Next
									</Button>
								) : (
									<Button
										variant="contained"
										style={{margin: '12px', padding: '.4rem 2rem'}}
										onClick={handleFinish}>
										<span>Confirm Logistics</span>
									</Button>
								)}
							</Grid>
						</Paper>
					</Grid>
				)}
			</Grid>
			{isMobile && (
				<Grid
					container
					alignItems={'center'}
					justifyContent={'space-between'}
					bgcolor={currentStep !== 1 ? 'white' : 'unset'}
					margin={currentStep === 1 ? '1rem' : 'unset'}
					position={currentStep !== 1 ? 'sticky' : 'unset'}
					bottom={currentStep !== 1 ? '0' : 'unset'}
					zIndex={currentStep !== 1 ? '1' : 'unset'}>
					{currentStep > 1 && (
						<Button style={{margin: '12px'}} variant="contained" onClick={handlePrev}>
							Previous
						</Button>
					)}
					{currentStep !== 1 && (
						<Typography variant="subtitle1" color={'black'}>
							{`${currentStep}/${steps.length}`}
						</Typography>
					)}
					{currentStep < steps.length ? (
						<Button
							variant="contained"
							style={{margin: '12px', padding: '.4rem 2rem'}}
							onClick={handleNext}>
							Next
						</Button>
					) : (
						<Button variant="contained" onClick={handleFinish}>
							Confirm Logistics
						</Button>
					)}
				</Grid>
			)}
		</Grid>
	);
};

export default WizardCustomer;
