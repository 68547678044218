import React, {useState} from 'react';
import {Box, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/ExitToApp';
// import { withRouter } from "react-router-dom";
import leftlogo from '../assets/images/apsrtc-cargo-logo.png';
import {useNavigate} from 'react-router-dom';

const DrawerMenu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const navigate = useNavigate();

	const navigateToRoute = (route) => {
		setIsOpen(false);
		if (!route || window.location.pathname.includes(route)) return;
		navigate(route);
	};

	const logout = () => {
		navigate('/logout', {replace: true});
	};

	return (
		<React.Fragment>
			<IconButton onClick={() => setIsOpen(true)} style={{color: 'white'}}>
				<MenuIcon />
			</IconButton>
			<Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)} className="customer-drawer">
				<Box style={{backgroundColor: '#ee2a3a'}}>
					<img src={leftlogo} alt="APSRTC" style={{height: '54px', width: 'auto', padding: '8px'}} />
				</Box>
				<List>
					<ListItem button onClick={() => navigateToRoute('/customers/booking/form')}>
						<ListItemIcon>
							<AddIcon />
						</ListItemIcon>
						<ListItemText primary="Create Booking" />
					</ListItem>
					<ListItem button onClick={() => navigateToRoute('/customers/bookings/list')}>
						<ListItemIcon>
							<ListAltIcon />
						</ListItemIcon>
						<ListItemText primary="My Bookings" />
					</ListItem>
				</List>
				<Divider />
				<List>
					<ListItem button onClick={logout}>
						<ListItemIcon>
							<LogoutIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItem>
				</List>
			</Drawer>
		</React.Fragment>
	);
};

export default DrawerMenu;
