import {Container, Grid, Typography} from '@mui/material';
import imgContainer from "../../assets/images/container.png";
import imgIcon1 from "../../assets/images/icon-1.png";
import imgIcon2 from "../../assets/images/icon-2.png";
import imgIcon3 from "../../assets/images/icon-3.png";
import imgIcon4 from "../../assets/images/icon-4.png";

const Features = () => {
    return (
        <Container sx={{margin: '2rem auto'}}>
            <Grid container>
                <Grid item xs={12} md={6} style={{paddingLeft: '3rem'}}>
                    <Grid container direction="column" gap={5}>
                        <Grid item xs={12}>
                            <Grid container gap="1rem">
                                <Grid item>
                                    <img
                                        src={imgIcon1}
                                        width="70"
                                        height="70"
                                        alt=""
                                    />
                                </Grid>
                                <Grid container xs alignItems="center">
                                    <Typography variant="h5" sx={{fontWeight:"bolder", fontSize: '1.7rem'}}>
                                        D2D Shipping
                                    </Typography>
                                    <Typography variant="body1">
                                        From Your Doorstep to Any Destination -
                                        Seamless Shipping Simplified.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container gap="1rem">
                                <Grid item>
                                    <img
                                        src={imgIcon2}
                                        width="70"
                                        height="70"
                                        alt=""
                                    />
                                </Grid>
                                <Grid container xs alignItems="center">
                                    <Typography variant="h5" sx={{fontWeight:"bolder", fontSize: '1.7rem'}}>
                                        Affordable Prices
                                    </Typography>
                                    <Typography variant="body1">
                                        Value-driven Logistics Affordable Rates
                                        Premium Service.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container gap="1rem">
                                <Grid item>
                                    <img
                                        src={imgIcon3}
                                        width="70"
                                        height="70"
                                        alt=""
                                    />
                                </Grid>
                                <Grid container xs alignItems="center">
                                    <Typography variant="h5" sx={{fontWeight:"bolder", fontSize: '1.7rem'}}>
                                        Online Booking
                                    </Typography>
                                    <Typography variant="body1">
                                        Effortless Logistics at Your Fingertips
                                        - Book Online, Ship with Ease.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container gap="1rem">
                                <Grid item>
                                    <img
                                        src={imgIcon4}
                                        width="70"
                                        height="70"
                                        alt=""
                                    />
                                </Grid>
                                <Grid container xs alignItems="center">
                                    <Typography variant="h5" sx={{fontWeight:"bold", fontSize: '1.7rem'}}>
                                        Fast Delivery
                                    </Typography>
                                    <Typography variant="body1">
                                        Swift and Secure Your Deliveries, Our
                                        Priority.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{textAlign: 'end'}}>
                    <img src={imgContainer} width="500" alt="" style={{maxWidth: '100%'}} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Features;
