import React from 'react';
import {Box, Paper, Typography} from '@mui/material';

const CustomerTermsAndConditions = () => {
	return (
		<Box style={{padding: '1rem', width: '100%'}}>
			<Paper style={{padding: '1.5rem', marginBottom: '1rem'}}>
				<Typography variant="h6" align="center" style={{marginBottom: '1.5rem'}}>
					TERMS AND CONDITIONS FOR TRANSPORT OF GOODS BY APSRTC LOGISTICS{' '}
					<span style={{color: 'red'}}>for online</span>
				</Typography>
				<ol type="1" style={{fontSize: '1rem', color: 'black'}}>
					<li>
						<ol type="a">
							<li>
								<Typography>
									All consignments with proper packing will only be accepted for booking through
									APSRTC logistics and are transported entirely at owner's risk. Except under special
									circumstances and/or for good reasons, the Corporation will not take any
									responsibility for damage/shortage for the booked consignments.
								</Typography>
							</li>
							<li>
								<Typography>
									I.D. Proof of customer (Aadhaar, Voter I.D., Driving License and PAN Card etc.) is
									mandatory at the time of booking and delivery.
								</Typography>
							</li>
						</ol>
					</li>
					<li>
						<ol type="a">
							<li>
								<Typography>
									In case of consignment lost or damaged, the Corporation liability is limited to
									shipment value or declared value of the item, insured at the time of booking,
									subject to documentary proof (invoice).
								</Typography>
							</li>
							<li>
								<Typography>
									Rs. 25/- per LR will be charged at the time of booking for issue of COF (Certificate
									of Fact) for consignments already insured.
								</Typography>
							</li>
						</ol>
					</li>
					<li>
						<Typography>
							No claim will be entertained without invoice, other relevant documents and declaration at
							the time of booking.
						</Typography>
					</li>
					<li>
						<Typography>
							Transportation of goods excludes all dangerous item viz. Explosives, Hazardous,
							Inflammables, Contraband, prohibited, bullion, guns, currency notes, original documents,
							Jewelers or any other item not worth carriage.
						</Typography>
					</li>
					<li>
						<Typography>
							<strong>
								The Corporation reserves the right to refuse or to accept goods for booking without
								assigning any reasons.
							</strong>
						</Typography>
					</li>
					<li>
						<Typography>
							<strong>
								LR once generated cannot be cancelled / transferred and money will not be refunded.
							</strong>
						</Typography>
					</li>

					<li>
						<Typography>
							The consignor is responsible to provide the invoice, forms, documents, declaration etc,
							required as per the Government Authorities.
						</Typography>
					</li>
					<li>
						<Typography>
							e-way bill part-A has to be handed over by the consignor for consignment whose value is Rs.
							50,000/- or more as per statutory requirement.
						</Typography>
					</li>
					<li>
						<Typography>
							The consignor is liable for all consequences arising out of non declaration / incorrect
							declaration of the contents of the consignment.
						</Typography>
					</li>
					<li>
						<Typography>
							If any proceedings against the Corporation or its employees are taken by the Government
							Authorities for defect or deficiency in the documents/forms/declaration etc, the Corporation
							will be at liberty to detain the goods at the risk of consignee/consignor unless and until
							the loss suffered by the Corporation in that respect is made good.
						</Typography>
					</li>
					<li>
						<Typography>
							The Corporation has the right to arrange transport of the consignment or part through their
							own vehicles or through other modes.
						</Typography>
					</li>
					<li>
						<Typography>
							The Corporation will be at liberty to transship goods from one vehicle to another at the
							Corporation's option.
						</Typography>
					</li>
					<li>
						<Typography>
							Timings, departures and arrivals are not guaranteed and are subject to change without notice
							and Corporation will not be responsible for damages and loss due to delays.
						</Typography>
					</li>
					<li>
						<Typography>
							The Corporation is not responsible for loss or damage to goods by breakage/evaporation/theft
							weather condition, strikes, lock-out, riots, civil or political disturbances. Explosion,
							fire or accident to vehicles by which goods are transported or fire to godown where goods
							are stored.
						</Typography>
					</li>
					<li>
						<Typography>
							The Corporation is entitled to levy and collect freight at the prevailing rates calculated
							either on the basis of actual weight of the consignment or on the volume by CDM (Cubic
							Decimal Meters) calculation basis, whichever is higher.
						</Typography>
					</li>
					<li>
						<Typography>
							The Corporation has the right of re-measurement, re-weighment, re- classification of items,
							before the goods are delivered and collect the difference amount that may have been omitted
							or undercharged apart from levy of penalty as per the instructions in force.
						</Typography>
					</li>
					<li>
						<Typography>
							Freight and other charges, if not pre-paid are payable at the time of delivery. The
							Corporation has lien on goods carried for freight and other charges not paid.
						</Typography>
					</li>
					<li>
						<Typography>
							In case of credit transactions, the claims arising out of any transactions are not
							entertained. If the bills are not paid on or before due date. The consignor/consignee shall
							not be entitled to set off/adjust the freight arbitrarily from the bill against any claims
							made by them on the Corporation.
						</Typography>
					</li>
					<li>
						<Typography>
							For all the goods that are not taken delivery from our office within the grace period
							allowed (within 2 days of receipt at destination) are subject to levy of demurrage charges
							existing on the date of delivery.
						</Typography>
					</li>
					<li>
						<Typography>
							The Corporation reserves the right to sell in public auction the undelivered consignment,
							other than perishables, that are lying at a station for more than 30 days even after sending
							due notices to Consignee or Consignor as the case may be.
						</Typography>
					</li>
					<li>
						<Typography>
							In case of perishables lying undelivered the Corporation reserves the right to sell them on
							the same day after receipt in public auction without any notice.
						</Typography>
					</li>
					<li>
						<Typography>
							No claim can be made against the Corporation for damage, short delivery or deterioration of
							goods etc. if a certificate of damages and Shortages is not obtained from the person in
							control of the office effecting delivery in the form prescribed by the Corporation, at the
							time of delivery.
						</Typography>
					</li>
					<li>
						<Typography>
							Any claim must be lodged by the consignor/consignee in writing at the booking office of the
							Corporation within 7 days after the completion of transit of the docket. Any such claim by
							consignor after 7 days there of Corporation will not be entertained.
						</Typography>
					</li>
					<li>
						<Typography>
							No employee/Manager of the Corporation shall modify these terms and conditions.
						</Typography>
					</li>
					<li>
						<Typography>
							Suit shall not be entertained against the Corporation after the expiry of 3 months from the
							date of booking.
						</Typography>
					</li>
					<li>
						<Typography>
							The courts in the state of Andhra Pradesh only shall have jurisdiction in respect of all
							claims and matters arising out of booking of the consignment or of the entrustment of the
							goods for transport.
						</Typography>
					</li>
					<li>
						<Typography>
							Passengers hereby consent to receive communications by SMS or calls from APSRTC with regard
							to services provided by them.
						</Typography>
					</li>
					<li>
						<Typography>
							In respect of failure transactions, APSRTC will refund the amount applicable to the
							concerned mode of booking and as per rules in force from time to time by APSRTC.
						</Typography>
					</li>
					<li>
						<Typography>
							Refunds to passengers will be given normally in 14 days, If refunds are delayed more than 14
							days, customers may contact helpline telephone number at 0866 2570005 in all days all At
							24X7
						</Typography>
					</li>
				</ol>
			</Paper>

			<Paper style={{padding: '1.5rem', marginBottom: '1rem'}}>
				<Typography variant="h6" align="center" style={{marginBottom: '1.5rem'}}>
					List of Prohibited Items
				</Typography>
				<ol type="1">
					<li>
						<Typography>Petrol</Typography>
					</li>
					<li>
						<Typography>Gasoline</Typography>
					</li>
					<li>
						<Typography>Diesel Oil</Typography>
					</li>
					<li>
						<Typography>Kerosene Oil</Typography>
					</li>
					<li>
						<Typography>Methylated spirits</Typography>
					</li>
					<li>
						<Typography>Turpentine</Typography>
					</li>
					<li>
						<Typography>Acids</Typography>
					</li>
					<li>
						<Typography>Sulphur</Typography>
					</li>
					<li>
						<Typography>Coal Tar</Typography>
					</li>
					<li>
						<Typography>Gunpowder</Typography>
					</li>
					<li>
						<Typography>Guns (loaded) and Cartridges</Typography>
					</li>
					<li>
						<Typography>Un-tanned Leather, Skin, Feathers and Hides</Typography>
					</li>
					<li>
						<Typography>Raw Fish</Typography>
					</li>
					<li>
						<Typography>Dry Fish</Typography>
					</li>
					<li>
						<Typography>Crackers/Explosives</Typography>
					</li>
					<li>
						<Typography>Gas Cylinders containing Compressed Gas</Typography>
					</li>
					<li>
						<Typography>Dead Bodies of human beings and Animals</Typography>
					</li>
					<li>
						<Typography>Contraband Articles</Typography>
					</li>
					<li>
						<Typography>Unauthorized forest produce</Typography>
					</li>
					<li>
						<Typography>Bones/Horns</Typography>
					</li>
					<li>
						<Typography>Animals</Typography>
					</li>
					<li>
						<Typography>Batteries (not crated)</Typography>
					</li>
					<li>
						<Typography>Charcoal</Typography>
					</li>
					<li>
						<Typography>Unpacked Cinema Films</Typography>
					</li>
					<li>
						<Typography>Unpacked Cotton bales</Typography>
					</li>
					<li>
						<Typography>Unpacked Dry leaves</Typography>
					</li>
					<li>
						<Typography>Unpacked Woolen Goods</Typography>
					</li>
					<li>
						<Typography>Opium</Typography>
					</li>
					<li>
						<Typography>Narcotic preparations and Hemp</Typography>
					</li>
					<li>
						<Typography>Prohibited forest produce</Typography>
					</li>
					<li>
						<Typography>Any other item prohibited by law from time to time</Typography>
					</li>
					<li>
						<Typography>Gutkha / Paan Masala containing tobacco / Nicotine</Typography>
					</li>
					<li>
						<Typography>Scooters/Motor Cycles</Typography>
					</li>
					<li>
						<Typography>Human Hair</Typography>
					</li>
					<li>
						<Typography>Blood Worms (Salt Water Worms)</Typography>
					</li>
					<li>
						<Typography>Sun-mica rolled sheets</Typography>
					</li>
					<li>
						<Typography>Glass items</Typography>
					</li>
					<li>
						<Typography>Liquids, Oils, Inks and Paints</Typography>
					</li>
				</ol>
				<Typography>
					<strong>Note:</strong> Any other items notified by the State / Central Government from time to time.
				</Typography>
			</Paper>

			<Paper style={{padding: '1.5rem', marginBottom: '1rem'}}>
				<Typography variant="h6" align="center" style={{marginBottom: '1.5rem'}}>
					SMS Terms And Conditions:
				</Typography>
				<ul>
					<li>
						<Typography>
							Short Messaging Service (SMS SERVICE) is offered by APSRTC has add on service to Passengers.
						</Typography>
					</li>
					<li>
						<Typography>
							SMS will be sent only to the mobile number you have registered/given during the logistics
							booking.
						</Typography>
					</li>
					<li>
						<Typography>SMS will be sent at the time LR generated from the application.</Typography>
					</li>
					<li>
						<Typography>
							You may not receive SMS in case there are issues/problems with your mobile phone, mobile
							network reach and technical issues with your mobile network provider. In order to receive
							SMS, you must ensure that your mobile phone is switched on, is in working condition and
							connected to the mobile network of your mobile network provider at all times. If your mobile
							phone is switched off or is out of coverage, your mobile network provider may delete any SMS
							messages to be received by you.
						</Typography>
					</li>
					<li>
						<Typography>
							The time to deliver the SMS is dependent upon the traffic on the mobile network and whether
							your mobile phone is within reach and switched on and be guaranteed by APSRTC.
						</Typography>
					</li>
					<li>
						<Typography>
							APSRTC is not a mobile network operator and does not guarantee the delivery of SMS text
							messages.
						</Typography>
					</li>
					<li>
						<Typography>
							By registering/booking to the reservation, you confirm that you have provided the accurate
							mobile number to be reached via SMS and that you are the owner or its legitimate user of the
							mobile phone being used at the time of traveling. You acknowledge that using another
							person's mobile phone or providing inaccurate mobile phone number or unauthorized use of
							mobile phone number for receiving the SMS may entail disclosure of your confidential
							information which disclosure shall be at your sole risk.
						</Typography>
					</li>
					<li>
						<Typography>
							SMS SERVICE is provided with no warranties, express or implied. APSRTC is not responsible or
							liable or otherwise have any obligation to you for any loss, damage or expenses incurred
							directly or indirectly due to non-receipt of the SMS for any reasons whatsoever. In no event
							shall APSRTC be liable for any indirect or consequential damages. The maximum liability of
							APSRTC shall not exceed the fee paid under this receipt.
						</Typography>
					</li>
					<li>
						<Typography>
							SMS SERVICE such as Lr request, Pickup assignment, Pickup confirms, Manifest alerts etc.,
							may be discontinued at any time without prior notice.
						</Typography>
					</li>
					<li>
						<Typography>
							These Terms and Conditions are governed by the law of India and Passenger by booking for
							online reservation SMS SERVICE submits to the exclusive jurisdiction of the courts in
							Hyderabad.
						</Typography>
					</li>
					<li>
						<Typography>
							By accepting the Terms and Conditions, you specifically acknowledge and agree to the
							aforesaid terms and conditions.
						</Typography>
					</li>
				</ul>
			</Paper>
		</Box>
	);
};

export default CustomerTermsAndConditions;
