import React, {useState} from 'react';
import imgApsrtc from '../assets/images/apsrtc-cargo-logo.png';
import {AppBar, Toolbar, IconButton, Box, Container, Grid} from '@mui/material';
import {NavLink} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.css';

const Header = () => {
	const [expand, toggleExpand] = useState(false);
	const closeMenu = () => {
		toggleExpand(false);
	};
	return (
		<AppBar component="nav" position="static" className="navbar-container">
			<Toolbar sx={{alignItems: 'flex-end'}}>
				<Container disableGutters>
					<Grid container justifyContent="space-between">
						<img className="logo" src={imgApsrtc} alt="" height="50px" width="auto" />
						<IconButton
							color="inherit"
							edge="start"
							sx={{ml: 2, display: {md: 'none'}}}
							onClick={() => toggleExpand(!expand)}>
							<MenuIcon />
						</IconButton>
						<Box
							className="links-container"
							sx={{
								width: {xs: '100%', md: 'auto'},
								maxHeight: {xs: expand ? '300px' : '0px', md: 'unset'},
							}}>
							<Grid
								container
								gap="1rem"
								sx={{
									height: '100%',
									flexDirection: {xs: 'column', md: 'row'},
									alignItems: {xs: 'flex-start', md: 'center'},
									padding: {xs: '1rem', md: '0'},
									flexWrap: 'nowrap',
								}}>
								<NavLink className="nav-link active-bg-white" onClick={closeMenu} to="/">
									HOME
								</NavLink>
								<NavLink className="nav-link active-bg-sky-blue" onClick={closeMenu} to="/login">
									DOOR-DOOR SERVICES
								</NavLink>
								{/* <NavLink
                                    className="nav-link"
                                    onClick={closeMenu}
                                    to="/services"
                                >
                                    SERVICES
                                </NavLink> */}
								{/* <NavLink
                                    className="nav-link"
                                    onClick={closeMenu}
                                    to="/ratecalculator"
                                >
                                    RATE CALCULATOR
                                </NavLink> */}
								<NavLink className="nav-link" onClick={closeMenu} to="/track">
									TRACK AWB NO
								</NavLink>
								<NavLink className="nav-link" onClick={closeMenu} to="/contact">
									CONTACT
								</NavLink>
							</Grid>
						</Box>
					</Grid>
				</Container>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
