import React, { Component } from "react";
import Axios from "axios";
// import { logout } from '../shared/actions/postActions';
import Cookies from "universal-cookie";
// import { fetchPosts,setLocale } from '../shared/actions/postActions';
import storage from "redux-persist/es/storage";
const cookies = new Cookies();

export function ServiceInterceptor(
  apiURL,
  url,
  requestType,
  data,
  callback,
  errorcallback,
  headers = {},
) {
  function logout() {
    console.log("logout called");
    localStorage.clear();
    console.log(cookies.get("client_auth_token"));
    cookies.remove("client_auth_token", { path: "/" });
    if (cookies.get("client_auth_token") != undefined) {
      cookies.remove("client_auth_token");
    }
    console.log(
      "after deleting client_auth_token " + cookies.get("client_auth_token"),
    );
    console.log("logging out");
    storage.removeItem("persist:root");
    window.location.replace("/login");
  }

  switch (requestType) {
    case "POST":
      Axios.post(apiURL + url,
        data,
        headers,
      )
        .then((res) => {
          if (res) {
            callback(res.data);
          }
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.status === 401 &&
            apiURL != "/auth"
          ) {
            console.log("unauth");
            logout();
          }
          errorcallback(error);
        });

      break;

    case "GET":
      Axios.get(apiURL + url, {})
        .then((res) => {
          if (res) {
            callback(res.data);
          }
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.status === 401 &&
            apiURL != "/auth"
          ) {
            console.log("unauth");
            logout();
          }
          // errorcallback(error);
        });
      break;

    case "PUT":
      Axios.put(apiURL + url, data)
        .then((res) => {
          if (res) {
            callback(res.data);
          }
        })
        .catch(function (error) {
          if (
            error.response &&
            error.response.status === 401 &&
            apiURL != "/auth"
          ) {
            console.log("unauth");
            logout();
          }
          errorcallback(error);
        });
      break;

    default:
      break;
  }
}

export default ServiceInterceptor;
