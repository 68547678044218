import React, {Component} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@mui/material';
import {ServiceInterceptor} from '../apis/Service';
import {PAYMENT_MODE_PAID, RUPEE_SYMBOl, UPI_PAYMENT} from '../helpers/constants';
import {getImageFromBase64String} from '../helpers/utilities';
import {connect} from 'react-redux';

const PAYMENT_STATUS_CHECK_INTERVAL = 6; // Interval in seconds
const MAX_TIME_FOR_PAYMENT = 120; // 2 mins in sec

class BookingConfirmationDialog extends Component {
	enableIntervalInstance = null;
	paymentIntervalInstance = null;
	state = {
		isLoading: false,
		upiPaymentRes: null,
		timeEvaluated: MAX_TIME_FOR_PAYMENT,
		disableProceedButton: false,
		countDownTimer: this.props.submitDelay || 0,
	};

	componentDidMount() {
		if (
			this.props.data.paymentTypeId == UPI_PAYMENT &&
			(!this.props.configInfo || !this.props.configInfo.processUPIPaymentUrl)
		) {
			return this.props.showSnackbar && this.props.showSnackbar('error', 'Process payment endpoint not found!');
		}

		if (this.props.data.paymentTypeId == UPI_PAYMENT && this.props.data.paymentMode == PAYMENT_MODE_PAID) {
			this.setState({isLoading: true});
			const params = {
				totalAmount: this.props.data.payableAmount || this.props.data.totalAmount,
				email: this.props.data.email,
				endPoint: this.props.configInfo.processUPIPaymentUrl,
				customerBookingFlag: '1',
			};
			ServiceInterceptor(
				'/api/cargo',
				'/processBookingUPIPayment',
				'POST',
				params,
				(res) => {
					this.setState({
						isLoading: false,
						upiPaymentRes: res,
					});
					if (res.qrCodeString) this.startValidatingPaymentStatus();
					else this.props.onClose(false);
					if (res.status == '1') this.props.showSnackbar && this.props.showSnackbar('error', res.message);
				},
				(err) => {
					this.setState({isLoading: false});
					this.props.showSnackbar &&
						this.props.showSnackbar(
							'error',
							'UPI Payment is facing delay. Please proceed with Cash payment.'
						);
					this.props.onClose(false);
				}
			);
		} else {
			this.setState({disableProceedButton: true});
			this.enableIntervalInstance = setInterval(() => {
				let {countDownTimer} = this.state;
				countDownTimer -= 1;
				if (countDownTimer <= 0) {
					this.setState({disableProceedButton: false});
					clearInterval(this.enableIntervalInstance);
				} else {
					this.setState({countDownTimer});
				}
			}, 1000);
		}
	}

	componentWillUnmount() {
		if (this.paymentIntervalInstance) clearInterval(this.paymentIntervalInstance);
		if (this.enableIntervalInstance) clearInterval(this.enableIntervalInstance);
	}

	startValidatingPaymentStatus = () => {
		this.paymentIntervalInstance = setInterval(() => {
			if (this.state.timeEvaluated <= 0) {
				this.props.showSnackbar && this.props.showSnackbar('error', 'Payment time over!');
				this.props.onClose(false);
				return clearInterval(this.paymentIntervalInstance);
			}
			this.setState({timeEvaluated: this.state.timeEvaluated - 1});

			if (this.state.timeEvaluated % PAYMENT_STATUS_CHECK_INTERVAL === 0) {
				this.validatePaymentStatus();
			}
		}, 1000);
	};

	validatePaymentStatus = () => {
		if (!this.state.upiPaymentRes) return;
		else if (!this.props.configInfo || !this.props.configInfo.paymentStatus) {
			clearInterval(this.paymentIntervalInstance);
			return this.props.showSnackbar && this.props.showSnackbar('error', 'Payment status endpoint not found!');
		}

		const params = {
			bankRefNo: this.state.upiPaymentRes.bankRefNo,
			endPoint: this.props.configInfo.paymentStatus,
			customerBookingFlag: '1',
		};
		ServiceInterceptor(
			'/api/cargo',
			'/getBookingUPIPaymentStatus',
			'POST',
			params,
			(res) => {
				if (res.statusCode == '0') {
					this.props.showSnackbar &&
						this.props.showSnackbar(res.status == 'success' ? 'success' : 'error', res.message);
					this.props.onClose(res.status == 'success', {
						bankRefNo: this.state.upiPaymentRes.bankRefNo,
					});
				}
			},
			(err) => {}
		);
	};

	render() {
		const isUPIPayment =
			this.props.data.paymentTypeId == UPI_PAYMENT && this.props.data.paymentMode == PAYMENT_MODE_PAID;
		const qrCodeString = this.state.upiPaymentRes && this.state.upiPaymentRes.qrCodeString;
		return (
			<Dialog
				open={this.props.isOpen}
				onClose={() => this.props.onClose(false)}
				fullWidth={true}
				maxWidth={qrCodeString ? 'md' : 'sm'}>
				<DialogTitle>Shipment Confirmation</DialogTitle>
				<DialogContent style={{padding: '1em'}}>
					<Grid container>
						<Grid item xs>
							<DialogContentText>
								<List>
									<ListItem>
										<Grid item xs={4} style={{textAlign: 'center'}}>
											<i className="fa fa-map-marker fa-lg" style={{marginRight: '9px'}}></i>
											Origin:
										</Grid>
										<Grid item xs={6} style={{paddingLeft: '15px'}}>
											<ListItemText primary={this.props.data.origin} />
										</Grid>
									</ListItem>

									<ListItem>
										<Grid item xs={4} style={{textAlign: 'center'}}>
											<i className="fa fa-map-marker fa-lg" style={{marginRight: '9px'}}></i>
											Destination:
										</Grid>
										<Grid item xs={6} style={{paddingLeft: '15px'}}>
											<ListItemText primary={this.props.data.destination} />
										</Grid>
									</ListItem>

									{this.props.data.pickupType && (
										<ListItem>
											<Grid item xs={4} style={{textAlign: 'center'}}>
												<i
													className="fa fa-arrow-up"
													style={{
														marginRight: '9px',
													}}></i>
												Pickup Type:
											</Grid>
											<Grid item xs={6} style={{paddingLeft: '15px'}}>
												<ListItemText primary={<span>{this.props.data.pickupType}</span>} />
											</Grid>
										</ListItem>
									)}

									{this.props.data.deliveryType && (
										<ListItem>
											<Grid item xs={4} style={{textAlign: 'center'}}>
												<i
													className="fa fa-arrow-down"
													style={{
														marginRight: '9px',
													}}></i>
												Delivery Type:
											</Grid>
											<Grid item xs={6} style={{paddingLeft: '15px'}}>
												<ListItemText primary={<span>{this.props.data.deliveryType}</span>} />
											</Grid>
										</ListItem>
									)}

									<ListItem>
										<Grid item xs={4} style={{textAlign: 'center'}}>
											<i className="fa fa-cart-arrow-down fa-lg" style={{marginRight: '9px'}}></i>
											Product:
										</Grid>
										<Grid item xs={6} style={{paddingLeft: '15px'}}>
											<ListItemText primary={this.props.data.product} />
										</Grid>
									</ListItem>

									{/* <ListItem>
										<Grid item xs={4} style={{textAlign: 'center'}}>
											<i className="fa fa-money fa-lg" style={{marginRight: '9px'}}></i>
											Payment Type:
										</Grid>
										<Grid item xs={6} style={{paddingLeft: '15px'}}>
											<ListItemText primary={this.props.data.paymentType} />
										</Grid>
									</ListItem> */}

									<ListItem>
										<Grid item xs={4} style={{textAlign: 'center'}}>
											<i className="fa fa-info-circle fa-lg" style={{marginRight: '9px'}}></i>
											Total Weight:
										</Grid>
										<Grid item xs={6} style={{paddingLeft: '15px'}}>
											<ListItemText primary={this.props.data.totalWeight} />
										</Grid>
									</ListItem>

									<ListItem
										style={{
											fontSize: '1rem',
											backgroundColor: '#ff660020',
										}}>
										<Grid item xs={4} style={{textAlign: 'center'}}>
											<i className="fa fa-money fa-lg" style={{marginRight: '9px'}}></i>
											Shipment Value:
										</Grid>
										<Grid item xs={6} style={{paddingLeft: '15px'}}>
											<ListItemText
												primary={
													<span
														style={{
															fontSize: '1rem',
															color: 'red',
														}}>
														{RUPEE_SYMBOl}
														{this.props.data.shipmentValue}
													</span>
												}
											/>
										</Grid>
									</ListItem>

									{/* <ListItem>
                                        <Grid item xs={4}>
                                            <i className="fa fa-truck fa-lg" style={{ marginRight: "9px" }}></i>
                                            Total Distance:
                                        </Grid>
                                        <Grid item xs={6} style={{ paddingLeft: "15px" }}>
                                            <ListItemText primary={`${this.props.data.totalDistance}`} />
                                        </Grid>
                                    </ListItem> */}

									<ListItem style={{fontSize: '1rem'}}>
										<Grid item xs={4} style={{textAlign: 'center'}}>
											<i className="fa fa-money fa-lg" style={{marginRight: '9px'}}></i>
											Bill Amount:
										</Grid>
										<Grid item xs={6} style={{paddingLeft: '15px'}}>
											<ListItemText
												primary={
													<span
														style={{
															fontSize: '1rem',
															color: 'green',
														}}>{`${RUPEE_SYMBOl}${this.props.data.totalAmount || 0}`}</span>
												}
											/>
										</Grid>
									</ListItem>

									{this.props.data.utrNo && (
										<ListItem>
											<Grid item xs={4}>
												<i
													className="fa fa-print fa-lg"
													style={{
														marginRight: '9px',
													}}></i>
												UTR No:
											</Grid>
											<Grid item xs={6} style={{paddingLeft: '15px'}}>
												<ListItemText primary={this.props.data.utrNo} />
											</Grid>
										</ListItem>
									)}
								</List>
							</DialogContentText>
						</Grid>
						{qrCodeString && (
							<Grid item style={{padding: '1rem'}}>
								{getImageFromBase64String(qrCodeString, {
									width: '200px',
									height: '200px',
									zoom: '1.5',
								})}
								<Typography align="center" variant="h6">
									Amount: {RUPEE_SYMBOl}
									{this.props.data.payableAmount || this.props.data.totalAmount}
								</Typography>
								<Typography align="center" variant="body1">
									Time Left for payment{' '}
									<span style={{color: 'red'}}>{getTimeFromSeconds(this.state.timeEvaluated)}</span>
								</Typography>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Typography style={{color: 'red'}}>Are you sure want to add shipment?</Typography>
					<Button
						variant="contained"
						onClick={() => this.props.onClose(false)}
						color="primary"
						disabled={this.state.isLoading}
						tabIndex={-1}>
					<span>Cancel</span>	
					</Button>
					<Button
						variant="contained"
						onClick={() => this.props.onClose(true)}
						color="primary"
						disabled={this.state.isLoading || isUPIPayment || this.state.disableProceedButton}
						tabIndex={-1}>
							<span>{this.state.disableProceedButton ? `${this.state.countDownTimer}s` : 'Submit'}</span>
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const mapStateToProps = (state) => ({
	configInfo: state.config && state.config.data,
});

export default connect(mapStateToProps)(BookingConfirmationDialog);

function getTimeFromSeconds(seconds) {
	if (!seconds || seconds < 0) return '0:0s';

	let m = parseInt(seconds / 60);
	let s = seconds % 60;

	if (m < 10) m = `0${m}`;
	if (s < 10) s = `0${s}`;

	const res = `${m}:${s}s`;
	return res;
}
