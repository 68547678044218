import React, {useEffect, useState} from 'react';
import {parseQueryParams} from '../helpers/utilities';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomerPaymentConfirmation = () => {
	const [cardMessage, setCardMessage] = useState(null);
	const checkPaymentLanding = () => {
		const queryParams = parseQueryParams(window.location.search);
		if (queryParams && queryParams.status && queryParams.message) {
			setCardMessage({
				type: queryParams.status === '0' ? 'success' : 'error',
				message: decodeURI(queryParams.message),
				id: queryParams.id,
			});
		} else {
			setCardMessage({
				type: 'error',
				message: queryParams.message || 'Error occured!',
			});
		}
	};

	const handleDone = () => {
		window.location.replace('/customers/booking/form');
	};

	useEffect(() => {
		checkPaymentLanding();
	}, []);

	let Icon = cardMessage && cardMessage.type === 'success' ? CheckCircleIcon : CancelIcon;

	return (
		<Box style={{padding: '1rem', width: '100%'}}>
			<Paper style={{padding: '1rem', maxWidth: '720px', margin: '0 auto'}}>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h6" style={{marginBottom: '1rem'}}>
							Verifying Payment
						</Typography>
					</Grid>
					<Grid container style={{gap: '0.5rem'}}>
						{cardMessage && (
							<React.Fragment>
								<Icon
									style={{
										color: cardMessage && cardMessage.type === 'success' ? 'green' : 'red',
									}}
								/>
								<Typography>{cardMessage.message}</Typography>
							</React.Fragment>
						)}
					</Grid>
					<Grid container justifyContent="center">
						<Button onClick={handleDone} variant="contained" color="primary">
							Done
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};

export default CustomerPaymentConfirmation;
