import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PDFGenerator from './PdfGenerator.jsx';
import {PDFDownloadLink} from '@react-pdf/renderer';
import {ServiceInterceptor} from '../apis/Service.jsx';
import {parseQueryParams} from '../helpers/utilities.js';

const CustomerInvoice = () => {
	const [isPdfReady, setIsPdfReady] = useState(false);
	const [customerInvoice, setCustomerInvoice] = useState(null);

	useEffect(() => {
		const queryParams = parseQueryParams(window.location.search);
		const payload = {
			bookingReferenceNo: decodeURIComponent(queryParams.id),
		};
		ServiceInterceptor('/api/customer', '/customer/invoice', 'POST', payload, (res) => {
			if (res.status === '0') {
				setCustomerInvoice(res);
			}
		});
	}, []);

	useEffect(() => {
		if (isPdfReady) {
			setTimeout(() => {
				const downloadButton = document.querySelector('#download-pdf');
				if (downloadButton) downloadButton.click();
			}, 250);
		}
	}, [isPdfReady]);

	return (
		<Paper
			style={{
				width: '720px',
				height: '300px',
				marginTop: '2rem',
				padding: '1rem',
			}}
			className="section-to-print show">
			<Grid container direction="column" alignItems="center">
				<Typography variant="h6">APSRTC LOGISTICS CUSTOMER INVOICE DOWNLOAD</Typography>
				{customerInvoice && (
					<PDFDownloadLink
						document={<PDFGenerator customerInvoice={customerInvoice} />}
						fileName="test.pdf"
						style={{
							color: 'blue',
							textDecoration: 'underline',
							textAlign: 'center',
							width: '100%',
							marginTop: '8%',
						}}>
						{({blob, url, loading, error}) => {
							if (!loading) setIsPdfReady(true);
							return loading ? (
								'Loading document...'
							) : (
								<Button variant="contained" color="primary" id="download-pdf">
									Download invoice
								</Button>
							);
						}}
					</PDFDownloadLink>
				)}
			</Grid>
		</Paper>
	);
};

export default CustomerInvoice;
