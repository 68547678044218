import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Typography, Grid, Tooltip, CircularProgress } from '@mui/material';
import SwitchCameraIcon from '@mui/icons-material/SwitchCamera';
import captureImage from '../assets/images/capture.gif';

let streamRef = null;

const ImageCapture = ({ id, title, show = false, onClose, onConfirm }) => {
    if (!title) title = "Capture Image";
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [activeCameraIndex, updateActiveCameraIndex] = useState(0);
    const [isOpen, setOpen] = useState(false);
    const [cameraInitError, updateCameraInitError] = useState(null);
    const [cameraActive, setCameraActive] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [devices, setDevices] = useState([]);
    const [isReady, makeReady] = useState(false);
    // const [counter, setCounter] = useState(0);

    const handleCanPlay = () => {
        const height = videoRef.current && videoRef.current.videoHeight || 0;
        const width = videoRef.current && videoRef.current.videoWidth || 0;

        canvasRef.current && canvasRef.current.setAttribute("width", width);
        canvasRef.current && canvasRef.current.setAttribute("height", height);
    }

    const handleCaptureImage = () => {
        if (capturedImage) {
            onConfirm && onConfirm(capturedImage, id);
            handleClose();
        } else {
            const context = canvasRef.current.getContext("2d");
            context.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight);
            setCapturedImage(canvasRef.current.toDataURL("image/png"));
            makeReady(true);
        }
    }

    const flushStreams = (callback = undefined) => {
        if(streamRef) {
            Promise.all([...streamRef.getVideoTracks().filter(t => t.readyState === 'live').map(track => track.stop())]).then(() =>  {
                // console.log('flush previously opened devices');
            }).catch(err => {
                // console.log('handle close stop tracks', err)
            }).finally(() => {
                callback && callback();
            }) 
        } else {
            callback && callback();
        }
    }
    const handleClose = () => {
        flushStreams(onClose);
    }

    const switchCamera = () => {
        if (devices && devices.length > 0) {
            if (activeCameraIndex == devices.length - 1)
                updateActiveCameraIndex(0);
            else updateActiveCameraIndex(activeCameraIndex + 1);
        }
    }

    const clearCapturedImage = () => {
        setCapturedImage(null);
    }

    // on mount event.
    useEffect(() => {
        setOpen(show);

        if(devices.length === 0 && navigator.mediaDevices) {
            navigator.mediaDevices.enumerateDevices()
            .then((devicesList) => {
                if (devicesList && devicesList.length > 0) {
                    const videoDevicesList = [...devicesList.filter(d => d.kind === 'videoinput')];
                    let availableDevicesList = [
                        ...videoDevicesList.filter(d => d.label.split(',').map(s => s.trim()).includes('facing back')),
                        ...videoDevicesList.filter(d => d.label.split(',').map(s => s.trim()).includes('facing front'))
                    ];
                    if(availableDevicesList.length === 0) {
                        availableDevicesList = [
                            videoDevicesList[(videoDevicesList.length - 1)],
                            videoDevicesList[0]
                        ];
                    }
                    setDevices(availableDevicesList);
                } else
                    throw new Error("No camera devices found!");
            })
            .catch((err) => {
                updateCameraInitError(`${err.name}: ${err.message}`);
            });
        } else if (!navigator.mediaDevices) {
            updateCameraInitError('No Camera devices found!');
        }
    }, []);

    useEffect(() => {
        flushStreams(openCamera);
    }, [activeCameraIndex]);

    useEffect(() => {
        openCamera();
    }, [devices])

    useEffect(() => {
        if(isReady) {
            setTimeout(() => {
                handleCaptureImage();
            }, 100);
        }
    }, [isReady])

    const openCamera = () => {
            if (devices.length === 0 || devices[activeCameraIndex] === undefined) return;

            const deviceId = devices[activeCameraIndex].deviceId;

            navigator.mediaDevices
                .getUserMedia({ video: { deviceId }, audio: false })
                .then((stream) => {
                    if(stream instanceof MediaStream) {
                        streamRef = stream;
                        videoRef.current.srcObject = stream;
                        videoRef.current.play();
                        setCameraActive(true);
                        updateCameraInitError(null);
                    }
                })
                .catch(err => {
                    handleCameraError('');
                });
    }

    const handleCameraError = (error) => {
        let errorMessage = error.message;
        if (error.name === 'ConstraintNotSatisfiedError') {
            errorMessage = 'Image Capture is not supported by your device';
        } else if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
            errorMessage = 'Permissions have not been granted to use camera, you need to allow camera permission to capture images.';
        }
        updateCameraInitError(errorMessage);
    }

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>
                <Grid container justifyContent="space-between" alignItems='center'>
                    {title}
                    <img id="custom" src={captureImage}
                        style={{height: '1PX', width: '1px'}} />
                    {devices && devices.length > 1 && cameraActive && !capturedImage && (
                        <Tooltip title="Switch Camera" color='primary'>
                            <SwitchCameraIcon onClick={switchCamera} />
                        </Tooltip>
                    )}
                </Grid>
            </DialogTitle>
            <DialogContent>
                {cameraInitError && (
                    <Typography color='error'>{cameraInitError || "Unable to access camera!"}</Typography>
                )}
                {!cameraActive && !cameraInitError && (
                    <Grid container justifyContent='center' alignItems='center' style={{ position: 'absolute', inset: 0 }}>
                        <CircularProgress />
                    </Grid>
                )}
                <DialogContentText style={{ display: cameraInitError ? 'none' : 'block' }}>
                    {capturedImage && (
                        <img src={capturedImage} style={{ width: "100%" }} />
                    )}
                    <video ref={videoRef} style={{ width: "100%", display: capturedImage ? "none" : "block" }} onCanPlay={handleCanPlay}>Video stream not available.</video>
                    <canvas ref={canvasRef} style={{ display: "none" }} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                  <span>Cancel</span> 
                </Button>
                {capturedImage && (
                    <Button onClick={clearCapturedImage} color="primary">
                    <span>Retake</span>   
                    </Button>
                )}
                <Button onClick={handleCaptureImage} color="primary" autoFocus disabled={!cameraActive && !capturedImage}>
                  <span>{capturedImage ? "Confirm" : "Capture"}</span>  
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ImageCapture;