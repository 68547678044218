import { FETCH_POSTS, USER_LOGOUT,SET_LOCALE, CARGO_POST, CONFIG_DATA_FETCHED, USER_PROFILE_FETCHED, SHIFT_CLOSED, CUSTOMER_DETAILS } from './types';
import Axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


export const fetchConfigurations = () => dispatch => {
    console.log("fetchConfigurations called");
    Axios.get("/api/customer/getConfiguration")
        .then(res => {
            if (res.data && res.data.paymentTypeList && res.data.paymentTypeList.length > 0) {
                dispatch({ type: CONFIG_DATA_FETCHED, data: res.data });
            }
        })
        .catch(function (error) { });
}