import {combineReducers} from 'redux';
import postReducer from './postReducer';
import configReducer from './configReducer';
import userReducer from './userReducer';
import customerReducer from './customerReducer';

export default combineReducers({
	res: postReducer,
	config: configReducer,
	user: userReducer,
	customer: customerReducer,
});
