import {Divider, Grid, Paper, Typography} from '@mui/material';
import imgBanner1 from '../../assets/images/banner-1.png';
import React from 'react';

const Contact = () => {
	return (
		<Paper style={{margin: '1rem', padding: '4rem 1rem'}}>
			<Grid container lg={12} alignItems="center">
				<Grid container flexDirection={'column'} alignContent={'center'} item lg={8} textAlign={'left'}>
					<Typography variant="h5">Mail I'd: </Typography>
					<Typography variant="h5" marginBottom={'.75rem'}>
						logistics@apsrtc.ap.gov.in
					</Typography>
					<Typography variant="h5">Contact No: </Typography>
					<Typography variant="h5" marginBottom={'.75rem'}>
						0866-2570006
					</Typography>
					<Typography variant="h5">Whats app No: </Typography>
					<Typography variant="h5">7993355619</Typography>
				</Grid>
				<Grid lg={4} paddingLeft={'6px'}>
					<img
						src={imgBanner1}
						className="img-fluid"
						width="100%"
						height="100%"
						alt=""
						style={{maxWidth: '100%', objectFit: 'contain', objectPosition: 'left'}}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default Contact;
