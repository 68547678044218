import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import ServiceInterceptor from '../apis/Service';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import {useSelector} from 'react-redux';

const CustomerCancellation = () => {
	const navigate = useNavigate();
	const {bookingId} = useParams();
	const [refund, setRefund] = useState(null);
	const [isLoading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [hasCancelled, setHasCancelled] = useState(false);
	const mobileNumber = useSelector((state) => state.customer.fromMobile);
	console.log(mobileNumber);
	useEffect(() => {
		if (bookingId) {
			handleRefundCall();
		}
	}, [bookingId]);
	const [errorMessage, setErrorMessage] = useState(false);
	// Refund api call
	const handleRefundCall = () => {
		const requestObject = {
			bookingId: bookingId || '',
			fromMobileNumber: mobileNumber?.value || '',
		};
		setLoading(true);
		ServiceInterceptor(
			'/api/customer',
			'/cancellationRefund',
			'POST',
			requestObject,
			(res) => {
				setLoading(false);
				if (res.status === '0') {
					setRefund(res);
				} else {
					setErrorMessage(res.message);
				}
			},
			(err) => {
				setLoading(false);
				console.error(err);
			}
		);
	};

	//On cancel
	const handleOnCancel = () => {
		setOpen(true);
	};
	const handleNavigateBack = () => {
		window.history.back();
	};
	const handleClose = () => {
		setOpen(false);
	};
	const onConfirmCancel = () => {
		const requestObject = {
			bookingId: bookingId || '',
		};

		ServiceInterceptor(
			'/api/customer',
			'/saveCancellation',
			'POST',
			requestObject,
			(res) => {
				if (res.status === '0') {
					setAlertMessage(res.message);
					setHasCancelled(true);
				} else {
					setErrorMessage(res.message);
				}
				setOpen(false);
			},
			(err) => {
				console.error(err);
				setOpen(false);
			}
		);
	};
	return (
		<Grid padding={1}>
			<Paper style={{margin: '1rem 1rem 0 1rem', padding: '.75rem'}}>
				<Typography variant="h6">Refund Details</Typography>
			</Paper>
			{isLoading ? (
				<Grid container direction="column" justifyContent={'center'} alignItems={'center'}>
					<CircularProgress />
					<Typography>Loading...</Typography>
				</Grid>
			) : (
				!errorMessage && (
					<Grid container padding={2} lg={12} style={{columnGap: '2.875rem'}}>
						{refund && refund.refundAmountDetails && refund.originalFares && !hasCancelled && (
							<Grid lg={9}>
								<Paper>
									<TableContainer>
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell>
														Cancellation ID :{' '}
														<strong style={{fontSize: '1rem'}}>{bookingId}</strong>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>Original Fare</TableCell>
													<TableCell style={{textAlign: 'end'}}>Refund Fare</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>Driver Incentives</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.driverIncenitve}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundDriverIncenitve}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>Loading Charges</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.loadingCharges}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundLoadingCharges}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>UnLoading Charges</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.unloadingCharges}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundUnloadingCharges}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Insurance</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.insurance}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundInsurance}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>
															{' '}
															Transhipment Charges
														</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.transhipmentCharges}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundTranshipmentCharges}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>
															{' '}
															Door Delivery Charges
														</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.doorDeliveryCharges}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundDoorDeliveryCharges}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Door Pickup Charges</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.doorPickupCharges}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundDoorPickupCharges}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Packing Charges</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.packingCharges}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundPackingCharges}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>
															{' '}
															Plastic Packing Charges
														</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.plasticPackingCharges}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundPlasticPackingCharges}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>
															{' '}
															Bank Transaction Fee
														</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.bankTransactionFee}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundBankTransactionFee}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Cgst</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.cgst}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundCgst || 0}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Sgst</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.sgst}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundSgst || 0}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Igst</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.igst || 0}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundIgst || 0}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>Net Charges</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.basicAmount}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundBasicAmount}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Round Off Amount</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.roundOffAmount}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundRoundOffAmount}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>Sub-Total Amount</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.subTotalAmount}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.refundSubTotalAmount}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}> Total Amount</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.originalFares.totalAmount}
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{(refund &&
															refund.refundAmountDetails &&
															refund.refundAmountDetails.refundTotalAmount) ||
															' '}
													</TableCell>
												</TableRow>
												<TableRow>
													<TableCell>
														<Typography fontWeight={'500'}>Cancellation Charges</Typography>
													</TableCell>
													<TableCell style={{textAlign: 'end'}}>-</TableCell>
													<TableCell style={{textAlign: 'end'}}>
														{refund.refundAmountDetails.cancellationCharges}
													</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</Grid>
						)}
						{!errorMessage && !hasCancelled && (
							<Grid
								container
								style={{
									flexDirection: 'column',
									marginTop: '.75rem',
									columnGap: '2.875rem',
								}}
								lg={2}>
								<Button
									variant="contained"
									color="primary"
									style={{marginBottom: '.75rem'}}
									onClick={() => handleOnCancel()}>
									Cancellation
								</Button>
								<Button
									variant="contained"
									style={{background: '#e0e0e0'}}
									onClick={() => handleNavigateBack()}>
									<Typography color={'black'} fontSize={'0.84375rem'}>
										Back
									</Typography>
								</Button>
							</Grid>
						)}
						<Dialog open={open} maxWidth="100%">
							<DialogTitle style={{maxWidth: '100%'}}>
								<Grid
									container
									justifyContent="space-between"
									alignItems="center"
									style={{flexWrap: 'nowrap', alignItems: 'baseline'}}>
									<Grid alignItems="center">Are You Sure That You Want To Cancel?</Grid>
									<Grid
										onClick={handleClose}
										style={{
											position: 'relative',
											top: '.375rem',
											alignItems: 'baseline',
										}}>
										<CloseIcon />
									</Grid>
								</Grid>
							</DialogTitle>
							<DialogActions>
								{
									<React.Fragment>
										<Button autoFocus variant="contained" color="primary" onClick={handleClose}>
											No
										</Button>
										<Button autoFocus variant="contained" color="primary" onClick={onConfirmCancel}>
											Confirm
										</Button>
									</React.Fragment>
								}
							</DialogActions>
						</Dialog>
					</Grid>
				)
			)}
			{alertMessage && hasCancelled && !errorMessage ? (
				<Paper style={{padding: '1rem', margin: '1rem'}}>
					<Grid item xs={12}>
						<Grid container>
							<CheckCircleIcon style={{color: 'green'}} />
							<Typography variant="body1">{alertMessage}</Typography>
						</Grid>
					</Grid>
					<Grid style={{textAlign: 'center'}}>
						<Button variant="contained" onClick={() => navigate('/customers/bookings/list')}>
							Okay
						</Button>
					</Grid>
				</Paper>
			) : (
				errorMessage && (
					<Paper style={{padding: '1rem', margin: '1rem'}}>
						<Grid item xs={12}>
							<Grid container>
								<Typography variant="body1">{errorMessage}</Typography>
							</Grid>
						</Grid>
					</Paper>
				)
			)}
		</Grid>
	);
};

export default CustomerCancellation;
