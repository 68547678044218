import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const Logout = () => {
	const navigate = useNavigate();
	useEffect(() => {
		deleteCookie('client_auth_token');
		navigate('/', {replace: true});
		//eslint-disable-next-line
	}, []);
	const deleteCookie = (name) => {
		const expires = new Date().toUTCString();
		localStorage.removeItem('customerDetails');
		document.cookie = `${name}=; expires=${expires}; path=/;`;
	};
	return null;
};

export default Logout;
