import { CONFIG_DATA_FETCHED } from "../actions/types";

const initialState = {
    data: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CONFIG_DATA_FETCHED:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}