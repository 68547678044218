import React from 'react';
import {Document, Page, View, Text} from '@react-pdf/renderer';
import moment from 'moment';

const PDFGenerator = ({customerInvoice}) => {
	return (
		<Document>
			<Page size="A7" wrap={false}>
				<View style={{padding: 10}}>
					<Text style={{fontSize: 15, fontWeight: 'bold', textAlign: 'center'}}> APSRTC LOGISTICS</Text>
					<Text style={{fontSize: 15, fontWeight: 'bold', textAlign: 'center'}}>BOOKING POINT COPY</Text>
					<Text style={{fontSize: 15, fontWeight: 'bold', textAlign: 'center'}}>
						{(customerInvoice && customerInvoice.fromPlaceName) || ''}
					</Text>
					<Text style={{fontSize: 12, fontWeight: 'bold', textAlign: 'center'}}>
						Date:{' '}
						{moment(new Date(customerInvoice && customerInvoice.bookedDateString)).format(
							'DD/MM/yyyy hh:mm'
						)}
					</Text>
					<Text style={{fontSize: 12, fontWeight: 'bold', textAlign: 'center'}}>
						Track Num:{(customerInvoice && customerInvoice.bookingId) || ''}
					</Text>
					<Text style={{fontSize: 10, fontWeight: 'bold', marginTop: 12}}>
						Sender:{(customerInvoice && customerInvoice.sender) || ''}
					</Text>
					<Text style={{fontSize: 10, fontWeight: 'bold', marginBottom: 12}}>
						Receiver:{(customerInvoice && customerInvoice.receiver) || ''}
					</Text>
					<View style={{borderTop: '1px dotted black', textAlign: 'center', display: 'flex'}}></View>
					{/* <Text style={{ fontSize: 14, fontWeight: 'bold' }}>Billing Period</Text> */}
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
							marginTop: 12,
						}}>
						<Text style={{width: '70%'}}>Transport Charges</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.tranportationCharges) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Loading Charges</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.loadingCharge) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Unloading Charges</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.unloadingCharge) || ''}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Driver Incentives</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.driverIncentives) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>SGST</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.sgst) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>CGST</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.cgst) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Transhipment Charges</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.transhipmentCharges) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Topay Charges</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.toPayCharges) || ''}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Door Pickup Charges</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.pickupCharges) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Door Delivery Charges</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.doorDeliveryCharges) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Insurance</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.insurance) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginTop: 12,
							marginBottom: 12,
						}}>
						<Text style={{width: '70%'}}>Total</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.totalAmount) || 0}
						</Text>
					</View>
					<View style={{borderTop: '1px dotted black', textAlign: 'center', display: 'flex'}}></View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginTop: 12,
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Pieces</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.noOfPieces) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Payment Type</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.paymentTypeName) || ''}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Item Type</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.itemType) || ''}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Weight</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.weight) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Product</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.productTypeName) || ''}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Total Distance</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.totalDistance) || 0}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Shipment Value</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.shipmentValue) || ''}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 2,
						}}>
						<Text style={{width: '70%'}}>Shipment Description</Text>
						<Text style={{width: '30%', textAlign: 'right'}}>
							{(customerInvoice && customerInvoice.description) || ''}
						</Text>
					</View>
					<View
						style={{
							fontSize: 10,
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginBottom: 12,
						}}>
						<Text style={{width: '70%'}}>User Name</Text>
						<Text style={{textAlign: 'right', fontSize: 10}}>
							{(customerInvoice && customerInvoice.userName) || ''}
						</Text>
					</View>
				</View>

				<Text style={{fontSize: 10}}>Thank you for your business!</Text>
			</Page>
		</Document>
	);
};

export default PDFGenerator;
