import React, { Component } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from '@mui/material';
import { CARGO_STATUS_BOOKED, CARGO_STATUS_DELIVERED, CARGO_STATUS_DEMURRAGE_APPLIED, CARGO_STATUS_MANIFESTED, CARGO_STATUS_MISTOUTE_RECEIVED, CARGO_STATUS_PARTIAL_DELIVERED, CARGO_STATUS_PARTIAL_RECEIVED, CARGO_STATUS_RECEIVED, CARGO_STATUS_RECEIVED_AT_TRANSSHIPMENT, CARGO_STATUS_READY_TO_DELIVER} from '../helpers/constants';

const BOOKING_IMAGES_POPUP = 1;
const RECEIVING_IMAGES_POPUP = 2;
const DELIVERY_IMAGES_POPUP = 3;
const TRANSSHIPMENT_RECEIVED_IMAGES_POPUP = 4;
const MISROUTE_RECEIVED_IMAGES_POPUP = 5;

class CargoTrackDetailTable extends Component {
    render() {
        return (
            <Table className='full-width'>
                <TableHead>
                    <TableRow>
                        <TableCell>Date Time</TableCell>
                        <TableCell align="left">Transaction By</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Location</TableCell>
                        {!this.props.isGuestUser && (
                            <TableCell align="left">Images</TableCell>
                        )}
                    </TableRow>
                </TableHead>

                {this.props.trackResponse.serviceStatusList &&
                    [...this.props.trackResponse.serviceStatusList].reverse().map((dataLable, idx) => {
                        let itemDesc = '';
                        let itemContent = dataLable.placeName;
                        if (dataLable.statusId == CARGO_STATUS_PARTIAL_RECEIVED)
                            itemDesc += ` (${dataLable.noOfPartialReceived}/${dataLable.noOfBookings})`;
                        if (dataLable.pieceTypeName && dataLable.noOfpiece) {
                            itemDesc += ` (${dataLable.pieceTypeName}: ${dataLable.noOfpiece})`;
                        }
                        if (dataLable.statusId == CARGO_STATUS_PARTIAL_DELIVERED) {
                            itemDesc += ` (${dataLable.noOfPartialDelivered}/${dataLable.noOfBookings})`;
                        }
                        if (dataLable.statusId == CARGO_STATUS_DEMURRAGE_APPLIED)
                            itemContent = dataLable.demurrgeCharges;

                        let imgsButton = null;
                        if (dataLable.statusId == CARGO_STATUS_BOOKED) {
                            imgsButton = <Button color="primary" disabled={!dataLable.documentList || dataLable.documentList.length === 0} onClick={() => this.props.showImagePopup(BOOKING_IMAGES_POPUP, dataLable.documentList)}>Booking&nbsp;Images</Button>
                        } else if (dataLable.statusId == CARGO_STATUS_PARTIAL_RECEIVED || dataLable.statusId ==CARGO_STATUS_RECEIVED) {
                            imgsButton = <Button color="primary" disabled={!dataLable.documentList || dataLable.documentList.length === 0} onClick={() => this.props.showImagePopup(RECEIVING_IMAGES_POPUP, dataLable.documentList)}>Receiving&nbsp;Images</Button>
                        } else if (dataLable.statusId == CARGO_STATUS_RECEIVED_AT_TRANSSHIPMENT) {
                            imgsButton = <Button color="primary" disabled={!dataLable.documentList || dataLable.documentList.length === 0} onClick={() => this.props.showImagePopup(TRANSSHIPMENT_RECEIVED_IMAGES_POPUP, dataLable.documentList)}>Transhipment&nbsp;Receiving&nbsp;Images</Button>
                        } else if (dataLable.statusId == CARGO_STATUS_MISTOUTE_RECEIVED) {
                            imgsButton = <Button color="primary" disabled={!dataLable.documentList || dataLable.documentList.length === 0} onClick={() => this.props.showImagePopup(MISROUTE_RECEIVED_IMAGES_POPUP, dataLable.documentList)}>MisRoute&nbsp;Receiving&nbsp;Images</Button>
                        } else if (dataLable.statusId == CARGO_STATUS_DELIVERED) {
                            imgsButton = <Button color="primary" disabled={!dataLable.documentList || dataLable.documentList.length === 0} onClick={() => this.props.showImagePopup(DELIVERY_IMAGES_POPUP, dataLable.documentList)}>Delivery&nbsp;Images</Button>
                        }else if (dataLable.statusId == CARGO_STATUS_READY_TO_DELIVER) {
                            imgsButton = <Button color="primary" disabled={!dataLable.documentList || dataLable.documentList.length === 0} onClick={() => this.props.showImagePopup(DELIVERY_IMAGES_POPUP, dataLable.documentList)}>Rack&nbsp;Images</Button>
                        }

                        let serviceInfo = null
                        if (dataLable.statusId == CARGO_STATUS_MANIFESTED) {
                            serviceInfo = this.props.trackResponse.serviceMappingList[dataLable.manifestInfoIndex || 0];
                        }

                        return (
                            <TableBody>
                                <TableCell align="left">{dataLable.dateTime}</TableCell>
                                <TableCell align="left">{dataLable.createdByName}</TableCell>
                                <TableCell align="left">{dataLable.statusStr}{itemDesc}</TableCell>
                                <TableCell align="left">{itemContent}
                                    {dataLable.statusId == CARGO_STATUS_MANIFESTED && serviceInfo && !this.props.isGuestUser ?
                                        <React.Fragment>
                                            <br />Driver No: {serviceInfo.driverNumber}, Vehicle No: {serviceInfo.vehicleNumber}, Depot Name: {serviceInfo.depotName}
                                        </React.Fragment> : ''
                                    }
                                </TableCell>
                                {!this.props.isGuestUser && (
                                    <TableCell>{imgsButton}</TableCell>
                                )}
                            </TableBody>
                        )
                    })}
            </Table>
        );
    }
}

export default CargoTrackDetailTable;

