import React from 'react';
import {Outlet} from 'react-router-dom';
import CustomerAppbar from './CustomerAppbar';
import {Grid} from '@mui/material';

const Layout = () => {
	return (
		<Grid container direction={'column'}>
			<Grid item>
				<CustomerAppbar />
			</Grid>
			<Grid item>
				<Outlet />
			</Grid>
		</Grid>
	);
};

export default Layout;
