import {Box} from "@mui/material";
import TrackAwb from "./TrackAwb/TrackAwb";
import Features from "./Features/Features";
import Hero from "./Hero/Hero";
import Footer from "./Footer/Footer";

const Home = () => {
    return (
        <Box className="home-container" sx={{ backgroundColor: "#ffffff" }}>
            <Hero />
            <TrackAwb />
            <Features />
            <Footer />
        </Box>
    );
};

export default Home;
